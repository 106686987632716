.container {
	width:100%;
	display:flex;
	justify-content:space-between;
	flex-direction:row;
	overflow:hidden;
	flex-wrap:wrap;
}
.loading {
	margin:40px 0 0 30px;
}
.main {
	display:flex;
	justify-content:space-between;
	align-items:flex-start;
	width:1024px;
	margin:10px auto 0;
	overflow:hidden;
	position:relative;
}
.main .column {
	width:33%;
	margin:5px;
}
.main .column .header {
	text-transform:uppercase;
	font-weight:600;
	color:#fff;
	margin-bottom:10px;
	padding:10px;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	border-radius:3px;
	box-shadow:0 1px 2px rgba(37,48,62,.2);
	background:#ffa500;
	flex-wrap:wrap;
}
.main .column .header.info {
	background:#999;
}
.main .column .header.order {
	position:sticky;
	top:0;
	background:#369;
	z-index:2;
}
.main .column .header img {
	width:22px;
	filter:invert(1);
}
.main .column .currentsummary {
	margin:15px 0 0;
	padding:10px 20px;
	background:rgba(255,255,255,.2);
	width:100%;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
}
.main .column .currentsummary span {
	font-weight:normal;
	font-size:110%;
	text-transform:none;
}
.main .column .currentsummary span span {
	display:block;
	font-weight:bold;
	white-space:nowrap;
}
.main .column .container {
	display:block;
	width:100%;
	height:calc(100vh - 234px);
	/* height:calc(100vh - 200px); */
	/*height:calc(100vh - 290px);*/
	overflow:auto;
}
.main .column .containerinfo {
	display:block;
	width:100%;
	height:auto;
	overflow:auto;
}
.main .column .item {
	position:relative;
	padding:10px;
	margin:0 0 10px;
	border-radius:3px;
	background:#fff;
	box-shadow:0 1px 2px rgba(37,48,62,.2);
	cursor:pointer;
	border:2px solid transparent;
}
.main .column .itemwide {
	border:none;
	border-radius:0;
	box-shadow:none;
}
.main .column .alarm {
	background:rgba(204,0,0,.2);
}
.main .column .paid {
	background:rgba(0,204,0,.2);
}
.main .column .selected {
	border:2px solid #555;
}
.main .column .item .oneline {
	display:flex;
	justify-content:space-between;
	align-items:center;
}
.main .column .item .amount {
	font-size:120%;
	font-weight:bold;
	background:#eee;
	padding:2px 6px;
	border-radius:3px;
	white-space:nowrap;
}
.main .column .item .products {
	margin:20px 0 10px;
	font-size:90%;
}
.main .column .item .date {
	color:#999;
}
.main .column .item .status {
	font-size:90%;
	padding:0 6px;
	border-radius:3px;
	background:#eee;
}
.main .column .empty {
	margin:10px 5px;
	font-weight:bold;
}
.main .column .button {
	font-size:20px;
	color:#fff;
	margin-bottom:10px;
	height:120px;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	border-radius:3px;
	box-shadow:0 1px 2px rgba(37,48,62,.2);
	background:#aaa;
}
.main .column .button.empty {
	margin:0 0 10px 0;
	background:#e0e0e0;
	box-shadow:none;
}
.main .column .button div {
	position:relative;
	margin:0 auto;
	text-align:center;
}
.main .column .button img {
	display:block;
	width:40px;
	margin:0 auto 10px;
}
.main .column .button div .icomingcount {
	position:absolute;
	width:20px;
	top:-2px;
	left:105px;
}
.main .column .button.admin {
	background:#555;
}
.main .column .button.shift {
	background:#6c42a6;
}
.main .column .button.shiftclose {
	background:#146f84;
}
.main .column .button.report {
	background:#333;
}
.main .column .button.calc {
	background:#693;
}
.main .column .button.wharehouse {
	background:#843014;
}
.main .column .button.orderadd {
	background:#6c42a6;
}
.main .column .button.ordersdate {
	background:#999;
}
.main .column .button.ordersdate.ordersdatealarm {
	background:#c00;
	cursor:pointer;
}
.main .column .button.orderaddroom {
	background:#278245;
}
.main .column .button.ordersearch {
	background:#146f84;
}
.main .column .button.orders {
	background:#f6c453;
}
.main .column .button.sectionsreport {
	background:#843014;
}
.main .column .button.admin,
.main .column .button.shift,
.main .column .button.shiftclose,
.main .column .button.report,
.main .column .button.calc,
.main .column .button.logist,
.main .column .button.wharehouse,
.main .column .button.ordersdate,
.main .column .button.orderaddroom,
.main .column .button.orderadd,
.main .column .button.orders,
.main .column .button.sectionsreport,
.main .column .button.ordersearch {
	cursor:pointer;
}
.main .column .buttonrow {
	width:50%;
	border-right:2px solid rgba(255,255,255,.6);
}
.main .column .buttonrow:last-child {border:none;}
.main .column .button.ordersdate .datecounter {
	position:absolute;
	top:6px;
	right:20px;
	background:#fff;
	width:27px;
	height:27px;
	border-radius:30px;
	display:flex;
	align-items:center;
	justify-content:center;
	color:#c00;
	font-size:90%;
	font-weight:bold;
}
.main .column .button.ordersdate .cancelcounter {
	right:45px;
	color:#333;
	background:#ddd;
}
.orderinfocontainer {
	width:calc(66% - 5px);
	height:calc(100vh - 85px);
	margin-top:5px;
	overflow:hidden;
	background:#fff;
	border-radius:3px 3px 0 0;
}
.orderinfocontainer .header {
	position:relative;
	padding:20px 10px;
	background:#fff;
	border-bottom:1px solid #ccc;
}
.orderinfocontainer .header h4 .status {
	position:relative;
	top:-2px;
	font-size:80%;
	font-weight:normal;
	margin-left:15px;
	padding:2px 8px 3px;
	color:#fff;
	border-radius:3px;
}
.orderinfocontainer .header h4 .status.statusnew {
	background:#999;
}
.orderinfocontainer .header h4 .status.statuspaid {
	background:#690;
}
.orderinfocontainer .orderbody {
	height:calc(100vh - 275px);
	padding:20px 10px;
	overflow:auto;
}
.orderbodysearch {
	height:calc(100vh - 275px) !important;
}
.orderbodycancel {
	height:calc(100vh - 205px) !important;
}
.orderbody .sections {
	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-direction:row;
}
.orderbody .sections .section {
	width:25%;
	height:100px;
	display:flex;
	justify-content:center;
	align-items:center;
	flex-direction:column;
	color:#fff;
	font-size:120%;
	margin:5px;
	cursor:pointer;
}
.orderbody .sections .section.rolls {
	background:#82cdde;
}
.orderbody .sections .section.burgers {
	background:#9ac0e1;
}
.orderbody .sections .section.shashlik {
	background:#8193ca;
}
.orderbody .sections .section.napitki {
	background:#a386be;
}
.orderbody .sections .section.wok {
	background:#369;
}
.orderbody .sections .section img {
	display:block;
	width:30px;
}
.orderbody .products {
	display:flex;
	align-items:center;
	flex-direction:row;
	flex-wrap:wrap;
}
.orderbody .products .productitem {
	width:calc(25% - 10px);
	height:120px;
	display:flex;
	justify-content:center;
	align-items:center;
	flex-direction:column;
	font-size:110%;
	margin:5px;
	padding:5px;
	text-align:center;
	background:#eee;
	cursor:pointer;
}
.orderbody .products .productitem span {
	display:block;
	margin-top:5px;
	font-size:90%;
}
.orderbody .products .productitem.cat0 {
	background:#9ac0e1;
}
.orderbody .products .productitem.cat1 {
	background:#9ac0e1;
}
.orderbody .products .productitem.cat2 {
	background:#9ac0e1;
}
.orderbody .products .productitem.cat3 {
	background:#8193ca;
}
.orderbody .products .productitem.cat4 {
	background:#a386be;
}
.orderbody .products .productitem.cat5 {
	background:#9ac0e150;
}
.orderbody .products .productitem.cat6 {
	background:#a386be50;
}
.orderbody .categories {
	display:flex;
	align-items:center;
	flex-direction:row;
	flex-wrap:wrap;
}
.orderbody .categories .catitem {
	width:calc(25% - 10px);
	height:100px;
	display:flex;
	justify-content:center;
	align-items:center;
	flex-direction:column;
	font-size:120%;
	color:#fff;
	margin:5px;
	text-align:center;
	background:#eee;
	cursor:pointer;
}
.orderbody .categories .catitem img {
	display:block;
	width:30px;
}
.orderbody .categories .catitem span {
	display:block;
	margin-top:5px;
	font-size:90%;
}
.orderbody .categories .catitem.cat {
	background:#82cdde;
}
.orderbody .categories .catitem.back {
	color:#333;
}
.ordercallcontainer {
	width:calc(66% - 5px);
	height:calc(100vh - 85px);
	margin-top:5px;
	overflow:hidden;
	background:#fff;
	border-radius:3px 3px 0 0;
}
.ordercallcontainer .header {
	position:relative;
	padding:20px 10px;
	background:#fff;
	border-bottom:1px solid #ccc;
}
.ordercallcontainer .header h4 {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.ordercallcontainer .orderbody {
	height:calc(100vh - 275px);
	padding:20px 10px;
	overflow:auto;
}
.ordercallcontainer .orderbodyclient {
	height:calc(100vh - 145px);
}
.clientinfo .cell {
	margin:0 10px 10px 0;
}
.clientinfo .cell label span {
	display:block;
	margin:0 0 2px 0;
	color:#777;
	white-space:nowrap;
}
.clientinfo .cell select {
	display:block;
	width:100%;
	padding:7px 10px;
	font-size:110%;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.clientinfo .cell select option {
	font-size:110%;
}
.clientinfo .cell select:focus {
    background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}
.clientinfo .cell button {
	margin-top:10px;
	padding:10px 20px;
	background:#333;
	color:#fff;
	border:none;
	border-radius:3px;
	text-transform:uppercase;
	text-align:center;
}
.clientinfo .cell button:hover {
	opacity:.8;
	cursor:pointer;
}
.clientinfo .cell button.add {
	margin-left:20px;
	color:#333;
	padding:8px 18px;
	font-weight:bold;
	background:transparent;
	border:2px solid #333;
}
.productinfo .orderlist {
	width:100%;
	height:calc(100vh - 360px);
	overflow:auto;
}
.productinfo .orderlist .row {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:flex-start;
	padding:5px;
	border-bottom:1px dashed #ccc;
}
.productinfo .orderlist .row:last-child {
	border:none;
}
.productinfo .orderlist .row .name {
	width:100%;
}
.productinfo .orderlist .row .quantity {
	width:50px;
	font-size:110%;;
}
.productinfo .orderlist .row .quantitytext {
	white-space:nowrap;
}
.productinfo .orderlist .row .measure {
	color:#999;
	font-size:90%;
}
.productinfo .orderlist .row .price {
	width:140px;
	text-align:right;
}
.productinfo .orderlist .row .delete {
	width:60px;
	text-align:right;
}
.productinfo .summary {
	padding:20px 10px 10px;
	background-color:#f2f2f2;
	background-image:linear-gradient(135deg, #fff 5px, transparent 5px), linear-gradient(225deg, #fff 5px, transparent 5px);
	background-size:10px 10px;
	background-repeat:repeat-x;
}
.productinfo .summary div.row {
	position:relative;
	display:flex;
	justify-content:space-between;
	flex-direction:row;
	flex-wrap:wrap;
}
.productinfo .summary div.row:before {
	content:'';
	position:absolute;
	bottom:5px;
	width:100%;
	height:0;
	line-height:0;
	border-bottom:2px dotted #ccc;
}
.productinfo .summary div.row span {
	background:#f2f2f2;
	padding-right:3px;
	z-index:1;
}
.productinfo .summary div.row span:last-child {
	background:#f2f2f2;
	padding-left:3px;
	z-index:1;
}
.productinfo .summary div.hint {
	width:100%;
	font-size:90%;
	color:#999;
	text-overflow:ellipsis;
}
.productinfo .cell {
	margin:0 10px 10px 0;
}
.ordercallcontainer .orderbody .clientcontainer .block {
	display:flex;
	flex-direction:column;
	padding:20px;
	padding-bottom:10px;
	margin-bottom:20px;
	background:#f2f2f2;
}
.ordercallcontainer .orderbody .clientcontainer .cell {
	display:flex;
	flex-direction:row;
	align-items:flex-end;	
}
.ordercallcontainer .orderbody .clientcontainer .cell:last-child {
	margin-right:0;
}
.ordercallcontainer .orderbody .clientcontainer .block input {
	width:100%;
}
.ordercallcontainer .orderbody .clientcontainer {
	overflow:hidden;
}
.ordercallcontainer .orderbody .clientcontainer .phoneblock {
	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-direction:row;
}
.ordercallcontainer .orderbody .clientcontainer .phoneblock .button {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	white-space:nowrap;
	padding:10px 15px;
	text-transform:uppercase;
	background:#999;
	border:none;
	color:#fff;
	font-size:100%;
	font-weight:bold;
	cursor:pointer;
	transition:.4s;
	margin-left:60px;
	border-radius:3px;
}
.ordercallcontainer .orderbody .clientcontainer .phoneblock .button:hover {
	background:#690;
}
.ordercallcontainer .orderbody .clientcontainer .phoneblock .button img {
	width:24px;
	margin-right:10px;
}
.ordercallcontainer .orderbody .clientcontainer input {
	width:100%;
}
.ordercallcontainer .orderbody .addresscontainer {
	height:100%;
	overflow:hidden;
}
.ordercallcontainer .orderbody .addresscontainer .addressblock {
	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-direction:row;
}
.ordercallcontainer .orderbody .addresscontainer .addressblock .button {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	white-space:nowrap;
	padding:10px 15px;
	text-transform:uppercase;
	background:#999;
	border:none;
	color:#fff;
	font-size:100%;
	font-weight:bold;
	cursor:pointer;
	transition:.4s;
	margin-left:60px;
	border-radius:3px;
}
.ordercallcontainer .orderbody .addresscontainer .addressblock .button:hover {
	background:#690;
}
.ordercallcontainer .orderbody .addresscontainer .addressblock .button img {
	width:24px;
	margin-right:10px;
}
.ordercallcontainer .orderbody .addresscontainer input {
	width:100%;
}
.ordercallcontainer .orderbody .addresscontainer .inputs {
	display:flex;
	flex-direction:row;
	align-items:flex-start;
	margin-top:10px;
}
.ordercallcontainer .orderbody .addresscontainer .inputs .row {
	width:25%;
	margin:5px 20px 0 0;
}
.ordercallcontainer .orderbody .addresscontainer .inputs .row:last-child {
	margin-right:0;
}
.ordercallcontainer .orderbody .addresscontainer .inputs input {
	display:block;
	width:100%;
}
.ordercallcontainer .orderbody .addresscontainer .inputs .row label {
	color:#999;
	font-size:90%;
}
.ordercallcontainer .orderbody .addresscontainer .inputs input[type=checkbox] {
	width:auto;
}
.ordercallcontainer .orderbody .addresseslist {
	height:calc(100vh - 305px);
	margin-top:10px;
	overflow:auto;
}
.ordercallcontainer .orderbody .addresseslist .container {
	display:flex;
	justify-content:space-between;
	align-items:center;
	padding:10px;
	border-bottom:1px solid #eee;
}
.ordercallcontainer .orderbody .addresseslist .container b {
	font-size:130%;
}
.ordercallcontainer .orderbody .addresseslist .container span {
	display:block;
	color:#777;
}
.ordercallcontainer .orderbody .addresseslist .container .button {
	padding:7px 20px;
	background:#333;
	color:#fff;
	border:none;
	border-radius:3px;
	text-transform:uppercase;
	text-align:center;
}
.ordercallcontainer .orderbody .addresseslist .container .button:hover {
	opacity:.8;
	background:#690;
	cursor:pointer;
}
.ordercallcontainer .orderbody .orderadditionals .block {
	display:flex;
	flex-direction:row;
	padding:20px;
	padding-bottom:10px;
	margin-bottom:20px;
	background:#f2f2f2;
}
.ordercallcontainer .orderbody .orderadditionals .discountsblock .cell {
	display:flex;
	flex-direction:row;
	align-items:flex-end;
}
.ordercallcontainer .orderbody .orderadditionals .discountsblock .cell:last-child {
	margin-right:0;
}
.ordercallcontainer .orderbody .orderadditionals .block input {
	width:100%;
}
.ordercallcontainer .orderbody .orderadditionals .commentblock .cell {
	margin-right:0;
}
.ordercallcontainer .orderbody .orderadditionals .commentblock input {
	width:100%;
}
.ordercallcontainer .orderbody .orderadditionals .blockdate {
	margin-bottom:0;
}
.ordercallcontainer .orderbody .orderadditionals .blockdate div {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.ordercallcontainer .orderbody .orderadditionals .blockdate div img {
	margin-left:20px;
	cursor:pointer;
}
.formcontainer {
	overflow:hidden;
}
.formcontainer .cell {
	margin:0 20px 20px 0;
}
.formcontainer .cell {
	white-space:nowrap;
}
.formcontainer .cell label span {
	display:block;
	margin:0 0 5px 0;
	color:#777;
}
.formcontainer .cell label div {
	white-space:nowrap;
}
.formcontainer input {
	display:block;
	width:240px;
	font-size:140%;
	padding:10px 15px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.formcontainer input[type=checkbox] {
	width:20px !important;
	height:20px !important;
	margin-top:15px;
}
.formcontainer input:focus {
    background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}
.controlpanel {
	padding:10px;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	background:#ddd;
}
.controlpanel div {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
}
.controlpanel .button {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	white-space:nowrap;
	padding:10px 20px;
	text-transform:uppercase;
	background:#690;
	font-size:120%;
	color:#fff;
	border:none;
	cursor:pointer;
	transition:.4s;
}
.controlpanel .button:hover {
	background:#333;
}
.controlpanel .button img {
	width:28px;
	margin-right:10px;
	filter:invert(1);
}
.controlpanel .button img.right {
	margin:0 0 0 10px;
}
.controlpanel .button.secondary {
	font-size:90%;
	background:#e0e0e0;
	border:2px solid #777;
	color:#333;
	padding:12px 18px 12px 12px;
	margin-left:10px;
}
.controlpanel .secondary:first-child {
	margin-left:auto;
}
.controlpanel .secondary img {
	margin:0 5px 0 0;
	filter:none;
	width:20px;
}
.controlpanel .button.cancel {
	background:rgba(204,0,0,.2);
	border-color:#c00;
}
.controlpanel .button.disabled {
	background:#aaa;
	opacity:.5;
	cursor:default;
}
.controlpanel .button.logist {
	background:#000;
	border-color:#000;
	color:#fff;
}
.modalcontainer .header {
	padding:25px 20px 10px 20px;
	border-radius:5px 5px 0 0;
	background:#f2f2f2;
}
.modalcontainer .header h4 {
	margin-bottom:10px;
}
.modalcontainer .header.print {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.promptcontainer {
	margin:20px 20px;
}
.promptcontainer div {
	display:flex;
	align-items:center;
	flex-direction:row;
	margin-top:30px;
}
.promptcontainer .button {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	white-space:nowrap;
	padding:10px 30px;
	text-transform:uppercase;
	background:#690;
	font-size:120%;
	color:#fff;
	border:none;
	cursor:pointer;
	transition:.4s;
}
.promptcontainer .button:hover {
	background:#333;
}
.promptcontainer .button.secondary {
	background:#999;
	font-weight:normal;
	margin-left:20px;
}
.promptcontainer .button.button2 {
	margin-left:10px;
}
.promptcontainer .button.hold {
	background:#eee;
	color:#555;
	margin-left:auto;
}
.areaselectcontainer {
	margin-top:10px;
}
.areaselectcontainer p {
	margin:20px;
}
.areaselectcontainer .row {
	padding:10px 20px;
	border-bottom:1px solid #eee;
	display:flex;
	justify-content:space-between;
	align-items:center;
}
.areaselectcontainer .rowfirst {
	border-top:1px solid #eee;
}
.areaselectcontainer .row span {
	display:block;
	font-size:80%;
	color:#999;
}
.areaselectcontainer .button {
	padding:10px 0;
	width:180px;
	background:#333;
	color:#fff;
	border:none;
	border-radius:3px;
	text-transform:uppercase;
	text-align:center;
}
.areaselectcontainer .button.selected {
	background:#690;
}
.areaselectcontainer .button:hover {
	opacity:.8;
	cursor:pointer;
}
.ordersearchcontainer {
	width:100%;
	margin-top:5px;
	overflow:hidden;
	background:#fff;
	border-radius:3px 3px 0 0;
}
.ordersearchcontainer .header {
	position:relative;
	padding:20px 10px;
	background:#fff;
	border-bottom:1px solid #ccc;
}
.ordersearchcontainer .orderbody {
	padding:20px 10px;
}
.ordersearchcontainer .orderbody input {
	width:100%;
}
.ordersearchcontainer .orderbody .orderscontainer {
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
	align-content:flex-start;
	width:100%;
	height:calc(100vh - 280px);
	margin:10px auto 0;
	overflow:auto;
	padding-bottom:20px;
}
.ordersearchcontainer .orderbody .orderscontainerold {
	height:calc(100vh - 235px);
}
.ordersearchcontainer .orderbody .orderscontainer .orderitem {
	width:315px;
	max-height:220px;
	background:#f8f8f8;
	padding:20px;
	margin:5px;
	border-radius:3px;
	box-shadow:0 1px 2px rgba(37,48,62,.2);
	cursor:pointer;
	transition:.4s;
	overflow:hidden;
}
.ordersearchcontainer .orderbody .orderscontainer .orderitem .orderiteminner {
	width:100%;
	max-height:160px;
	overflow:hidden;
}
.ordersearchcontainer .orderbody .orderscontainer .orderitem:hover {
	opacity:.9;
}
.ordersearchcontainer .orderbody .orderscontainer .orderitem .block {
	display:flex;
	justify-content:space-between;
	align-items:flex-start;
	margin-bottom:10px;
}
.ordersearchcontainer .orderbody .orderscontainer .orderitem .block:first-child {
	align-items:center;
}
.ordersearchcontainer .orderbody .orderscontainer .orderitem .number {
	font-size:110%;
	margin-bottom:5px;
	font-weight:bold;
}
.ordersearchcontainer .orderbody .orderscontainer .orderitem .amount {
	font-size:120%;
	font-weight:bold;
	white-space:nowrap;
	margin-left:10px;
	background:#eee;
	padding:2px 6px;
	border-radius:3px;
}
.ordersearchcontainer .orderbody .orderscontainer .orderitem .products {
	margin:10px 0 10px;
	font-size:90%;
}
.ordersearchcontainer .orderbody .orderscontainer .orderitem .status {
	font-size:80%;
	padding:0 6px;
	border-radius:3px;
	color:#555;
	background:#eee;
}
.areaactive {
	position:absolute;
	top:20px;
	left:45%;
	display:flex;
	align-items:center;
}
.areaactive button {
	display:flex;
	align-items:center;
	margin-right:20px;
	font-size:100%;
	color:#333;
	border:none;
	padding:8px 14px;
	transition:.4s;
	border-radius:5px;
}
.areaactive button img {
	width:18px;
	margin-right:5px;
}
.calccontainer {
	display:flex;
	justify-content:space-between;
	align-items:flex-end;
	margin:20px;
}
.calccontainer .form {
	width:460px;
	margin-bottom:-10px;
}
.calccontainer .form p {
	margin-bottom:40px;
	font-size:140%;
	line-height:1.2;
}
.calccontainer .form p span {
	display:block;
	margin-top:10px;
	font-size:80%;
	color:#555;
}
.calccontainer .form .column {
	position:relative;
}
.calccontainer .form .column .placeholder {
	position:absolute;
	left:4px;
	top:4px;
	width:150px;
	height:52px;
	padding:10px;
	display:flex;
	align-items:center;
	line-height:1.2;
	background:#999;
	color:#fff;
}
.calccontainer .form .column .input {
	padding:10px;
	width:440px;
	height:60px;
	border:none;
	font-size:28px;
	margin-bottom:10px;
	padding-left:175px;
	background:#fff;
	border:2px solid #aaa;
}
.calccontainer .form .column .focus {
	border-color:rgba(0,0,255);
	box-shadow:0 1px 1px rgba(0,0,0,.075) inset, 0 0 8px rgba(0,0,255,.6);
	outline:0 none;
}
.calccontainer .keypad {
	width:280px;
	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-wrap:wrap;
}
.calccontainer .keypad .num {
	width:90px;
	height:60px;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-bottom:5px;
	font-size:32px;
	font-weight:700;
	color:#fff;
	background:#555;
	cursor:pointer;
}
.calccontainer .keypad .num:active {
	background:#ccc;
	color:#333;
}
.calccontainer .keypad .num:active {
	background:#ccc;
	color:#333;
}
.calccontainer .keypad .delete {
	font-size:24px;
}
.calccontainer .keypad .numlast {
	margin:0;
}
.calccontrols {
	display:flex;
	align-items:center;
	justify-content:space-between;
	margin:20px;
}
.calccontrols button {
	width:100%;
	border:none;
	font-size:20px;
	font-weight:bold;
	padding:15px;
	color:#fff;
	background:#328f51;
	cursor:pointer;
}
.calccontainer .sum {
	font-size:300%;
	font-weight:bold;
	margin-bottom:20px;
}
.calcresults {
	margin:20px 0 0 30px;
}
.calcresults div {
	display:flex;
	flex-direction:row;
}
.calcresults div span {
	font-size:140%;
}
.calcresults div span:first-child {
	width:180px;
}
.calcresults .result {
	margin-top:20px;
}
.calcresults div.resultplus {
	color:#690;
}
.keyboard {
	bottom:10px;
	left:10px;
}
.keyboardphone {
	bottom:150px;
	left:10px;
}
.keyboardpromo {
	top:200px;
	left:10px;
}
.keyboardaddress {
	left:10px;
	top:310px;
}
.dailyreport {
	display:block !important;
	margin-top:15px !important;
}
.dailyreport .row {
	display:block;
	margin:0 0 5px 0;
	padding:0 0 5px 0;
	border-bottom:1px solid #ccc;
}
.dailyreport .rowoneline {
	display:flex;
	align-items:center;
	flex-direction:row;
}
.dailyreport .rowoneline .title {
	width:280px;
}
.dailyreport .row:last-child {
	border:none;
}
.dailyreport .row .title {
	font-weight:bold;
}
.dailyreport .row .cell {
	display:flex;
	align-items:center;
	flex-direction:row;
	margin:0;
	padding:0;
	font-size:110%;;
}
.dailyreport .row .cell span:first-child {
	display:block;
	width:150px;
}
.promptcontainer .cancelinput input {
	display:block;
	width:100%;
	font-size:160%;
	padding:10px 15px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
	letter-spacing:10px;
}
.promptcontainer .cancelinput input:focus {
    background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}
.promptcontainer .focus {
	border-color:rgba(0,0,255) !important;
	box-shadow:0 1px 1px rgba(0,0,0,.075) inset, 0 0 8px rgba(0,0,255,.6) !important;
	outline:0 none !important;
}
.promptcontainer .keypadpin {
	width:280px;
	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-wrap:wrap;
	margin:30px auto;
}
.promptcontainer .keypadpin .num {
	width:90px;
	height:60px;
	display:flex;
	align-items:center;
	justify-content:center;
	margin:0 0 5px 0 !important;
	font-size:32px;
	font-weight:700;
	color:#fff;
	background:#555;
	cursor:pointer;
}
.promptcontainer .keypadpin .num:active {
	background:#ccc;
	color:#333;
}
.promptcontainer .keypadpin .delete {
	font-size:24px;
}
.promptcontainer .keypadpin .numlast {
	margin:0;
}
.promptcontainer .buttonwide {
	width:100%;
	margin:0 auto;
	padding:15px;
	justify-content:center;
}
.storecontainer h4 {
	margin-bottom:15px;
}
.storecontainer .productslist, .storecontainer .requestslist, .storecontainer .productslistselect {
	width:100%;
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
	justify-content:space-between;
	align-items:center;
	margin-bottom:20px;
}
.storecontainer .productslist li, .storecontainer .requestslist li, .storecontainer .productslistselect li  {
	width:48%;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:flex-start;
	padding:5px;
	border-bottom:1px dashed #ccc;
}
.storecontainer .productslist li:last-child {
	border:none;
}
.storecontainer .productslistselect li label {
	width:100%;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:flex-start;
}
.storecontainer .productslistselect li label input {
	width:auto;
	margin-right:10px;
}
.storecontainer .productslist li .name, .storecontainer .requestslist li .name, .storecontainer .productslistselect label .name {
	width:100%;
}
.storecontainer .productslist li .measure, .storecontainer .requestslist li .measure, .storecontainer .productslistselect label .measure {
	color:#999;
	width:200px;
	text-align:right;
}
.downloadbutton {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	white-space:nowrap;
	text-transform:uppercase;
	cursor:pointer;
	font-weight:bold;
	padding:6px 14px;
	border:2px solid #333;
	background:rgba(255,255,255,.6);
	margin-right:15px;
}
.downloadbutton img, .addbutton img {
	width:24px;
	margin-right:10px;
	filter:none;
}
.addbutton {
	background:#690;
	border-color:#690;
	color:#fff;
}
.addbutton img {
	filter:invert(1);
}
.printbutton {
	text-transform:uppercase;
	cursor:pointer;
	font-weight:bold;
	padding:6px 14px;
	border:2px solid #333;
	background:rgba(255,255,255,.6);
	margin-left:20px;
	margin-top:-10px;
}
.promptcontainerblock .promptcontainercash {
	display:block;
	width:calc(50% - 40px);
}
.promptcontainercash div {
	display:block;
	margin:0;
}
.promptcontainercash .title {
	margin-bottom:10px;
	font-size:120%;
	font-weight:600;
}
.promptcontainercash .money {
	font-size:200%;
	font-weight:700;
}
.promptcontainercash input {
	font-weight:700;
	font-size:200%;
	padding:10px 15px;
	letter-spacing:unset;
}
.promptcontainercash ul {
	display:flex;
	flex-direction:row;
	align-items:center;
	margin-top:15px;
	margin-bottom:20px;
}
.promptcontainercash ul li {
	padding:5px 10px;
	margin-right:10px;
	border-radius:5px;
	background:#eee;
	cursor:pointer;
}
.promptcontainercash ul li.selected {
	background:#555;
	color:#fff;
}
.promptcontainercash .control {
	margin-top:20px;
}
.promptcontainercash .title, .promptcontainerblock .nums .title {
	margin-bottom:0;
	font-size:120%;
	font-weight:normal;
}
.promptcontainerblock .nums .title {
	margin-bottom:10px;
}
.promptcontainerblock {
	display:flex;
	flex-direction:row;
	align-items:flex-start;
}
.promptcontainerblock .nums {
	padding:20px;
	width:calc(50% - 10px);
}
.promptcontainerblock .cancelinput {
	flex-direction:column;
	align-items:flex-start;
	justify-content:flex-start;
}
.promptcontainerblock .cancelinput p {
	margin-bottom:20px;
	font-size:130%;
}
.promptcontainerblock .cancelinput input {
	display:block;
	width:100%;
	font-size:140%;
	padding:10px 15px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.promptcontainerblock .cancelinput input:focus {
    background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}
.promptcontainerblock .cancelinput label {
	display:flex;
	flex-direction:row;
	align-items:center;
	font-size:120%;
	margin-top:20px;
}
.promptcontainerblock .cancelinput input[type=checkbox] {
	width:20px !important;
	height:20px !important;
	margin-right:10px;
}
.promptcontainerblock .input {
	padding:10px;
	width:440px;
	height:60px;
	border:none;
	font-size:28px;
	margin-bottom:10px;
	padding-left:175px;
	background:#fff;
	border:2px solid #aaa;
}
.promptcontainerblock .focus {
	border-color:rgba(0,0,255) !important;
	box-shadow:0 1px 1px rgba(0,0,0,.075) inset, 0 0 8px rgba(0,0,255,.6) !important;
	outline:0 none !important;
}
.promptcontainerblock .keypad {
	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-wrap:wrap;
	margin:20px 0 10px;
}
.promptcontainerblock .keypad .num {
	width:92px;
	height:60px;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-bottom:5px;
	font-size:32px;
	font-weight:700;
	color:#fff;
	background:#555;
	cursor:pointer;
}
.promptcontainerblock .keypad .num:active {
	background:#ccc;
	color:#333;
}
.promptcontainerblock .keypad .delete {
	font-size:24px;
}
.promptcontainerblock .keypad .numlast {
	margin:0;
}
.promptcontainertransfer .keypadpin {
	margin-bottom:0;
	width:auto;
}
.promptcontainertransfer .control {
	margin-top:10px;
}
.promptcontainertransfer .cancelinput input {
	letter-spacing:normal;
}
.allorders {
	margin:0 0 10px 5px;
}
.allorders button {
	padding:4px 10px;
	background:#fff;
	border-radius:5px;
	border-width:2px;
	cursor:pointer;
	font-size:90%;
}