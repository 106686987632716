.scrollcontrols {
	width:100%;
	padding:10px;
	background:#ccc;
}
.scrollcontrols .scrollcontrolsinner {
	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-direction:row;
}
.scrollcontrols .scrollcontrolsinner .buttonsblock {
	display:flex;
	align-items:center;
	flex-direction:row;
}
.scrollcontrols .scrollcontrolsinner .scrollbutton {
	font-size:160%;
	font-weight:bold;
	padding:2px 14px;
	text-align:center;
	border:2px solid #333;
	background:rgba(255,255,255,.6);
	cursor:pointer;
}
.scrollcontrols .scrollcontrolsinner .scrollbutton:active {
	background:#333;
	color:#fff;
}
.scrollcontrols .scrollcontrolsinner .up {
	margin-left:20px;
}
.scrollcontrols .scrollcontrolsinner .top {
	background:rgba(255,255,255,.3);
	margin-right:20px;
}