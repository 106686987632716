.container h4 {
	display:flex;
	align-items:center;
	justify-content:space-between;
}
.formcontainer {
	margin-top:20px;
	margin-bottom:100px;
    padding:20px;
    border-radius:4px;
    box-shadow:0 2px 2px rgba(204,197,185,.5);
    background:#fff;
    position:relative;
    z-index:1;
    zoom:1;
    overflow:hidden;
}
.formcontainer form {
	max-width:900px;
}
.container .linepositions {
	margin:30px 0 20px;
	padding-top:20px;
	border-top:1px solid #ccc;
}
.container .linepositions:first-child {
	border:none;
	margin:0;
	padding:0;
}
.container .productslist {
	max-width:900px;
	margin:20px 0;
}
.container .productslist li {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:flex-start;
	padding:5px;
	border-bottom:1px dashed #ccc;
}
.container .productslist li:last-child {
	border:none;
}
.container .productslist li .name {
	width:100%;
}
.container .productslist li .measure {
	color:#999;
	width:200px;
	text-align:right;
}