.container h4 {
	display:flex;
	align-items:center;
	justify-content:space-between;
}
.formcontainerinputs {
	display:flex;
	align-items:center;
	flex-direction:row;
	margin:10px 0 20px;
}
.formcontainerinputs .cell {
	width:20%;
	margin:0 10px 5px 0;
}
.formcontainerinputs .cellsmall {
	width:auto;
}
.formcontainerinputs .cell span {
	font-size:80%;
	color:#666;
}
.formcontainerinputs .cell div {
	display:flex;
	align-items:center;
	flex-direction:row;
}
.formcontainerinputs .cell img {
	margin:0 20px 0 10px;
}
.formcontainerinputs input, .formcontainerinputs select {
	display:block;
	width:100%;
	font-size:100%;
	padding:5px 10px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.formcontainerinputs select {
	margin-right:15px;
	padding:6px 10px;
}
.formcontainerinputs input:focus, .formcontainerinputs select:focus {
    background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}
.formcontainerinputs input[type=checkbox] {
	width:auto;
	margin:6px 0;
}
.searchblock {
	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-direction:row;
	margin-top:20px;
}
.searchblock button {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	white-space:nowrap;
	padding:10px 15px;
	text-transform:uppercase;
	background:#999;
	border:none;
	color:#fff;
	font-size:100%;
	font-weight:bold;
	cursor:pointer;
	transition:.4s;
	margin-left:15px;
	border-radius:3px;
}
.searchblock .button:hover {
	background:#690;
}
.searchblock input {
	display:block;
	width:100%;
	font-size:100%;
	padding:10px 10px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.searchblock input:focus {
	background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}