.container {
	width:100%;
	display:flex;
	justify-content:space-between;
	flex-direction:row;
	overflow:hidden;
	flex-wrap:wrap;
}
.loading {
	margin:40px 0 0 30px;
}
.main {
	display:flex;
	flex-direction:row;
	flex-wrap:nowrap;
	align-content:flex-start;
	justify-content:flex-start;
	width:1024px;
	margin:10px auto 0;
	padding-bottom:20px;
	overflow:hidden;
}
.main .column {
	width:73%;
	margin-right:10px;
}
.main .columnsingle {
	width:27%;
	padding:5px 5px 0 5px;
	background:#fff;
}
.main .maininner {
	display:flex;
	flex-direction:row;
	flex-wrap:nowrap;
	align-content:flex-start;
	justify-content:flex-start;
	width:100%;
}
.main .maininner .mainorders {
	width:calc(68% - 10px);
	margin-right:10px;
}
.main .maininner .maincouriers {
	width:32%;
	background:#fff;
}
.main .column .header {
	width:100%;
	text-transform:uppercase;
	font-weight:600;
	color:#fff;
	margin-bottom:10px;
	padding:10px;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	border-radius:3px;
	box-shadow:0 1px 2px rgba(37,48,62,.2);
	background:#ffa500;
}
.main .column .header.info {
	background:#999;
}
.main .column .header.order {
	position:sticky;
	top:0;
	background:#369;
	z-index:2;
}
.main .column .header img {
	width:22px;
	filter:invert(1);
}
.statbutton {
	position:relative;
	top:-3px;
	margin-left:20px;
	padding:4px 10px;
	background:#fff;
	border-radius:5px;
	border-width:2px;
	cursor:pointer;
	font-size:70%;
}
.main .column .container {
	display:flex;
	align-items:flex-start;
	height:calc(100vh - 270px);
	align-content:flex-start;
	overflow:auto;
}
.main .columnsingle .container {
	display:block;
	width:100%;
	height:calc(100vh - 195px);
	overflow:auto;
}
.main .item {
	width:235px;
	padding:20px;
	background:#f8f8f8;
	padding:20px;
	margin:0 0 10px;
	border-radius:3px;
	box-shadow:0 1px 2px rgba(37,48,62,.2);
	cursor:pointer;
	transition:.4s;
	overflow:hidden;
}
.main .columnsingle .item {
	width:auto;
	min-height:auto;
}
.main .itemlite {
	max-height:auto;
}
.main .item .inneritem {
	width:100%;
	max-height:160px;
	overflow:hidden;
}
.main .item.alarm {
	background:rgba(204,0,0,.2);
}
.main .item.paid {
	background:rgba(0,204,0,.2);
}
.main .item .defaultsend {
	background:#ecf0f1;
	padding: 3px 5px;
	display: inline-block;
	border-radius: 3px;
	color: black;
	margin-bottom:2px;
	font-size:70%;
}
.main .item .infosend {
	background: #f1c40f;
	padding: 3px 5px;
	display: inline-block;
	border-radius: 3px;
	color: white;
	font-weight: bold;
	margin-bottom:2px;
	font-size:70%;
}
.main .item .criticalsend {
	background: #e74c3c;
	padding: 3px 5px;
	display: inline-block;
	border-radius: 3px;
	color: white;
	font-weight: bold;
	margin-bottom:2px;
	font-size:70%;
}
.main .item .notsend {
	background: #4b4b4b;
	padding: 3px 5px;
	display: inline-block;
	border-radius: 3px;
	color: white;
	font-weight: bold;
	margin-bottom:2px;
	font-size:70%;
}
.main .item .warnsend {
	background:#e67e22;
	padding: 3px 5px;
	display: inline-block;
	border-radius: 3px;
	color: white;
	margin-bottom:2px;
	font-size:70%;
}
.main .item:hover {
	opacity:.9;
}
.main .item .block {
	display:flex;
	justify-content:space-between;
	align-items:flex-start;
	margin-bottom:10px;
}
.main .item .block div.ondate {
	font-size:70%;
	font-weight:normal;
	text-align:center;
	background:#eee;
	padding:2px 6px;
	border-radius:3px;
	line-height:1.2;
	color:#c00;
}
.main .item .block:first-child {
	align-items:center;
}
.main .item .number {
	font-size:110%;
	margin-bottom:5px;
	font-weight:bold;
	display:flex;
	justify-content:space-between;
	align-items:center;
}
.main .item .amount {
	font-size:120%;
	font-weight:bold;
	white-space:nowrap;
	margin-left:10px;
	background:#eee;
	padding:2px 6px;
	border-radius:3px;
}
.main .item .products {
	margin:10px 0 10px;
	font-size:90%;
}
.main .item .status {
	font-size:80%;
	padding:0 6px;
	border-radius:3px;
	color:#555;
	background:#eee;
}
.main .empty {
	margin:10px 5px;
	font-weight:bold;
}
.main .columntop {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:flex-start;
}
.main .columntop .columnblock {
	width:13.8%;
}
.main .columnblock .button {
	font-size:16px;
	color:#fff;
	margin-bottom:10px;
	height:120px;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	border-radius:3px;
	box-shadow:0 1px 2px rgba(37,48,62,.2);
	background:#aaa;
}
.main .columnblock .button.empty {
	margin:0 0 10px 0;
	background:#e0e0e0;
	box-shadow:none;
}
.main .columnblock .button div {
	position:relative;
	margin:0 auto;
	text-align:center;
}
.main .columnblock .button img {
	display:block;
	width:40px;
	margin:0 auto 10px;
}
.main .columnblock .button div .icomingcount {
	position:absolute;
	width:20px;
	top:-2px;
	left:105px;
}
.main .columnblock .button.couriers {
	background:#146f84;
}
.main .columnblock .button.couriersdone {
	background:#278245;
}
.main .columnblock .button.orders {
	background:#6c42a6;
}
.main .columnblock .button.map {
	background:#555;
}
.main .columnblock .button.cook {
	background:#843014;
}
.main .columnblock .button.close {
	background:#000;
}
.main .columnblock .button.couriersdone,
.main .columnblock .button.couriers,
.main .columnblock .button.map,
.main .columnblock .button.cook,
.main .columnblock .button.close,
.main .columnblock .button.orders {
	cursor:pointer;
}
.modalcontainer {
	overflow:hidden;
	border-radius:5px;
}
.modalcontainer .header {
	padding:25px 20px 10px 20px;
	border-radius:5px 5px 0 0;
	background:#f2f2f2;
}
.modalcontainer .header h4 {
	margin-bottom:10px;
}
.modalcontainer .header h4 .status {
	position:relative;
	top:-2px;
	font-size:80%;
	font-weight:normal;
	margin-left:15px;
	padding:2px 8px 3px;
	color:#fff;
	border-radius:3px;
}
.modalcontainer .header h4 .status.statuswork {
	background:#999;
}
.modalcontainer .header h4 .status.statusdone {
	background:#690;
}
.modalcontainer .header .mapbutton {
	position:absolute;
	top:20px;
	left:50%;
	display:flex;
	justify-content:space-between;
	align-items:center;
	padding:5px 25px 5px 15px;
	background:#fff;
	border-radius:5px;
	cursor:pointer;
	font-size:120%;
}
.modalcontainer .header .mapbutton img {
	width:32px;
	height:32px;
	margin-right:10px;
}
.modalcontainer .productslist {
	height:455px;
	padding:10px 20px 0;
	overflow:auto;
}
.modalcontainer .productslist .item {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	padding:10px 5px;
	border-bottom:1px solid #ccc;
}
.modalcontainer .done {
	padding:10px;
    background:#f2f2f2;
	border-radius:0 0 5px 5px;
	border-top:1px solid #ccc;
}
.modalcontainer .done .doneinner {
	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-direction:row;
}
.modalcontainer .done img {
	width:20px;
	margin-right:10px;
	filter:invert(1);
}
.modalcontainer .done button {
    padding:10px 20px;
    background:#333;
    color:#fff;
    border:none;
    border-radius:3px;
	margin-left:10px;
	text-transform:uppercase;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
}
.modalcontainer .done.finish button {
	background:#690;
}
.modalcontainer .donefinish button {
	padding:10px 20px;
    background:#690;
    color:#fff;
    border:none;
    border-radius:3px;
	margin-left:10px;
	text-transform:uppercase;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
}
.modalcontainer .donefinish img {
	width:20px;
	margin-right:10px;
	filter:invert(1);
}
.modalcontainer .done select {
	display:block;
	width:100%;
	padding:10px;
	font-size:120%;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.modalcontainer .done select:focus {
    background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}
.modalcontainer .done select option {
	font-size:120%;
}
.areaactive {
	position:absolute;
	top:20px;
	left:45%;
	display:flex;
	align-items:center;
}
.areaactive button {
	display:flex;
	align-items:center;
	margin-right:20px;
	font-size:100%;
	color:#333;
	border:none;
	padding:8px 14px;
	transition:.4s;
	border-radius:5px;
}
.areaactive button img {
	width:18px;
	margin-right:5px;
}
.mapcontainer {
	display:flex;
	flex-direction:column;
	width:100%;
}
.mapcontainer .map {
	position:relative;
	width:100%;
	height:510px;
	background:#eee url('../../../Images/loading.svg') 50% 50% no-repeat;
}
.mapcontainerorder {
	display:flex;
	flex-direction:row;
	width:100%;
}
.mapcontainerorder .map {
	position:relative;
	width:600px;
	height:600px;
	background:#eee url('../../../Images/loading.svg') 50% 50% no-repeat;
}
.mapcontainerorder .courierslist {
	width:300px;
	border-left:2px solid #ccc;
}
.mapcontainerorder .courierslist .rideinfocontainer {
	padding:5px 15px;
	background:#f2f2f2;
	border-top:1px solid #ccc;
}
.mapcontainerorder .courierslist .rideinfocontainer .empty {
	margin:10px 5px;
	font-weight:bold;
}
.mapcontainerorder .courierslist .rideinfocontainer .row, .routepanel .row {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	padding:2px;
}
.mapcontainerorder .courierslist .rideinfocontainer .row div, .routepanel .row div {
	font-size:90%;
}
.mapcontainerorder .courierslist .rideinfocontainer .row .label, .routepanel .row .label {
	color:#555;
}
.mapcontainerorder .courierslist .rideinfocontainer .row .value, .routepanel .row .value {
	font-weight:bold;
}
.mapcontainerorder .courierslist button.orderinfo {
    padding:10px 20px;
    background:#333;
    color:#fff;
    border:none;
    border-radius:3px;
	text-transform:uppercase;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	margin-top:10px;
}
.mapcontainerorder .courierslist .courierslistlist {
	height:330px;
	padding:5px 0 20px;
	overflow:auto;
}
.mapcontainerorder .courierslist .courierslistlistempty {
	height:358px;
}
.mapcontainerorder .courierslist .courierslistlist ul {
	margin:0;
	padding:0;
	list-style:none;
}
.mapcontainerorder .courierslist .courierslistlist ul li {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	padding:10px 15px;
	border-bottom:1px solid #eee;
	cursor:pointer;
}
.mapcontainerorder .courierslist .courierslistlist ul li.selected {
	background:#eee;
}
.mapcontainerorder .courierslist .courierslistlist ul li button {
	display:flex;
	align-items:center;
	font-size:70%;
	font-weight:bold;
	text-transform:uppercase;
	color:#fff;
	border:none;
	padding:8px 10px;
	cursor:pointer;
	border-radius:5px;
	background:#278245;
}
.mapcontainerorder .courierslist .courierslistlist ul li .oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.mapcontainerorder .courierslist .courierslistlist ul li .onlinestatus {
	width:6px;
	height:6px;
	border-radius:50%;
	margin-right:5px;
}
.mapcontainerorder .courierslist .courierslistlist ul li .onlinestatus.offline {
	background:#c00;
}
.mapcontainerorder .courierslist .courierslistlist ul li .onlinestatus.online {
	background:#278245;
}
.mapcontainerorder .courierslist .courierslistlist ul li .phone {
	font-size:80%;
	color:#555;
}
.routepanel {
	position:absolute;
	bottom:40px;
	left:380px;
	width:200px;
	height:90px;
	background:rgba(255,255,255,.9);
	border-radius:5px;
	border:1px solid #ccc;
	padding:10px;
}
.routepanelfull {
	bottom:80px;
	left:unset;
	right:10px;
	width:300px;
	height:110px;
}
.orderaddress {
	position:absolute;
	top:5px;
	left:5px;
	width:590px;
	display:flex;
	justify-content:space-between;
	align-items:center;
	font-size:100%;
	font-weight:bold;
	padding:10px 20px;
	border-radius:5px;
	background:rgba(255,255,255,.8);
}
.orderaddressstatic {
	position:static;
	width:100%;
	border-radius:0;
	background:#fff;
}
.orderaddress img.update {
	width:24px;
	height:24px;
	cursor:pointer;
}
.promptcontainer {
	margin:20px 20px;
}
.promptcontainer .onelinewide {
	display:flex;
	flex-direction:row;
	align-items:flex-start;
	justify-content:space-between;
}
.promptcontainer .reportrow {
	border-bottom:1px solid #ccc;
	padding:5px 2px;		
}
.promptcontainer .reportrowlast {
	border:none;
}
.promptcontainer .reportrowtotal {
	margin:5px -20px 0;
	border:none;
	padding:8px 22px;
	background:#eee;
}
.promptcontainer .control {
	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-direction:row;
	margin-top:20px;
}
.promptcontainer .button {
	width:100%;
	text-align:center;
	padding:10px 30px;
	text-transform:uppercase;
	background:#690;
	font-size:120%;
	color:#fff;
	border:2px solid #690;
	cursor:pointer;
	transition:.4s;
}
.promptcontainer .button:hover {
	background:#333;
	border:2px solid #333;
}
.promptcontainer .button.order, .promptcontainer .button.change {
	background:#e0e0e0;
	border:2px solid #777;
	color:#333;
	padding:8px 24px;
	width:49%;
}
.promptcontainer .button.change {
	background:#fff;
}
.promptcontainer .cancelinput {
	flex-direction:column;
	align-items:flex-start;
	justify-content:flex-start;
}
.promptcontainer .cancelinput p {
	font-size:130%;
}
.promptcontainer .cancelinput div.reportorderinfo {
	margin:10px 0 10px;
	padding:10px 15px;
	font-size:90%;
	border-radius:10px;
	background:#eee;
}
.promptcontainer .cancelinput input {
	display:block;
	width:100%;
	font-size:140%;
	padding:10px 15px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.promptcontainer .cancelinput input:focus {
    background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}
.promptcontainer .cancelinput label {
	display:flex;
	flex-direction:row;
	align-items:center;
	font-size:120%;
	margin-top:10px;
}
.promptcontainer .cancelinput input[type=checkbox] {
	width:20px !important;
	height:20px !important;
	margin-right:10px;
}
.promptcontainer .input {
	padding:10px;
	width:440px;
	height:60px;
	border:none;
	font-size:28px;
	margin-bottom:10px;
	padding-left:175px;
	background:#fff;
	border:2px solid #aaa;
}
.promptcontainer .focus {
	border-color:rgba(0,0,255) !important;
	box-shadow:0 1px 1px rgba(0,0,0,.075) inset, 0 0 8px rgba(0,0,255,.6) !important;
	outline:0 none !important;
}
.promptcontainer .keypad {
	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-wrap:wrap;
	margin:20px 0 10px;
}
.promptcontainer .keypad .num {
	width:92px;
	height:60px;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-bottom:5px;
	font-size:32px;
	font-weight:700;
	color:#fff;
	background:#555;
	cursor:pointer;
}
.promptcontainer .keypad .num:active {
	background:#ccc;
	color:#333;
}
.promptcontainer .keypad .delete {
	font-size:24px;
}
.promptcontainer .keypad .numlast {
	margin:0;
}
.switchcontainer {
	position:absolute;
	top:20px;
	left:30%;
	display:flex;
	align-items:center;
}
.switchcontainer button {
	display:flex;
	align-items:center;
	margin-right:20px;
	font-size:100%;
	color:#333;
	border:none;
	padding:8px 14px;
	cursor:pointer;
	border-radius:5px;
}
.switchcontainer button img {
	width:18px;
	margin-right:5px;
}
.mainfull {
	width:1024px;
	margin:10px auto 0;
	padding-bottom:20px;
	overflow:hidden;
	background:#fff;
}
.mainfull .header {
	position:relative;
	padding:20px 10px;
	background:#fff;
	border-bottom:1px solid #ccc;
}
.mainfull .inner {
	height:calc(100vh - 200px);
	padding:20px;
	padding-bottom:0;
	overflow:auto;
}
.mainfull .inner .row {
	display:flex;
	align-items:flex-start;
	flex-direction:row;
	padding:20px 0;
	border-bottom:1px solid #ccc;
}
.mainfull .inner .row:last-child {
	border:none;
}
.mainfull .inner .row .name {
	width:280px;
}
.mainfull .inner .row .name b {
	display:block;
	font-size:120%;
}
.mainfull .inner .row .stat {
	display:flex;
	align-items:center;
	justify-content:flex-start;
	flex-direction:column;
	width:80px;
	padding:5px;
	text-align:center;
	font-size:90%;
	border-left:1px solid #ccc;
}
.mainfull .inner .row .statlast {
	margin-right:20px;
	border-right:1px solid #ccc;
}
.mainfull .inner .row .stat span {
	font-weight:bold;
}
.mainfull .inner .row .numdate {
	width:240px;
}
.mainfull .inner .row .numdate span {
	display:block;
	font-size:120%;
}
.mainfull .inner .row .status {
	width:220px;
}
.mainfull .inner .row .couriername {
	width:320px;
}
.mainfull .inner .row .couriername b {
	display:block;
	font-size:120%;
}
.mainfull .inner .row .infobutton {
	display:flex;
	flex-direction:row;
	align-items:center;
	padding:10px 15px;
	text-transform:uppercase;
	background:#333;
	border:none;
	color:#fff;
	font-size:100%;
	font-weight:bold;
	cursor:pointer;
	transition:.4s;
	margin-left:auto;
	border-radius:3px;
}
.mainfull .inner .row .infobutton:hover {
	background:#690;
}
.mainfull .inner .row .orderslist {
	width:520px;
	display:flex;
	align-items:flex-start;
	flex-direction:row;
	flex-wrap:wrap;
}
.mainfull .inner .row .orderslist .orderblock {
	width:160px;
	font-size:90%;
	height:90px;
	padding:10px;
	margin:0 5px 10px 5px;
	background:#f8f8f8;
	border-radius:3px;
	box-shadow:0 1px 2px rgba(37,48,62,.2);
	cursor:pointer;
	transition:.4s;
	overflow:hidden;
}
.mainfull .inner .row .orderslist .orderblock:active {
	background:#ccc;
}
.mainfull .inner .row .orderslist .orderblock span {
	display:block;
}
.mainfull .map {
	position:relative;
	width:100%;
	height:calc(100vh - 80px);
	background:#eee url('../../../Images/loading.svg') 50% 50% no-repeat;
}
.controlbutton {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	white-space:nowrap;
	padding:8px 15px;
	text-transform:uppercase;
	background:#690;
	color:#fff;
	border:none;
	cursor:pointer;
	transition:.4s;
}
.controlbutton img {
	width:24px;
	margin-right:10px;
	filter:invert(1);
}
.controlbutton:hover {
	background:#333;
}
.promptcontaineralert {
	margin:20px 20px;
}
.promptcontaineralert div {
	display:flex;
	align-items:center;
	flex-direction:row;
	margin-top:30px;
}
.promptcontaineralert .button {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	white-space:nowrap;
	padding:10px 30px;
	text-transform:uppercase;
	background:#690;
	font-size:120%;
	color:#fff;
	border:none;
	cursor:pointer;
	transition:.4s;
}
.promptcontaineralert .button:hover {
	background:#333;
}
.promptcontaineralert .button.secondary {
	background:#999;
	font-weight:normal;
	margin-left:20px;
}
.promptcontaineralert .button.button2 {
	margin-left:10px;
}
.promptcontaineralert .button.hold {
	background:#eee;
	color:#555;
	margin-left:auto;
}
.moneyreport {
	margin-top:10px;
}
.moneyreportold {
	margin-left:-10px;
	padding:10px;
	border-radius:5px;
	background:#eee;
}
.moneyreportcurrent {
	margin-left:-10px;
	padding:5px 0 10px 10px;
}
.moneyreportold div {
	margin-bottom:5px;
}
.moneyreportold button, .moneyreportcurrent button {
	padding:4px 10px;
	background:#fff;
	border-radius:5px;
	border-width:2px;
	cursor:pointer;
	font-size:90%;
}
.oldorders {
	margin:0 0 10px 5px;
}
.oldorders button {
	padding:4px 10px;
	background:#fff;
	border-radius:5px;
	border-width:2px;
	cursor:pointer;
	font-size:90%;
}
.switcher {
	position:relative;
	height:24px;
	margin-top:10px;
}
.switcher > span {
	font-weight: bold;
	margin-top: 2.5px;
	margin-left: 5px;
	position: absolute;
}
.switch {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 24px;
}
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
	border-radius: 34px;
}
.slider:before {
	position: absolute;
	content: "";
	height: 18px;
	width: 18px;
	left: 3px;
	bottom: 3px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
	border-radius: 50%;
}
input:checked + .slider {
	background-color: #27ae60;
}
input:focus + .slider {
	box-shadow: 0 0 1px #27ae60;
}
input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}
input:checked + .slider:before {
	content: "\2714";
	font-size: 14px;
	color: white;
	text-align: center;
	line-height: 18px;
}
.main .maincouriers .maincourierslist {
	display:flex;
	align-items:flex-start;
	height:calc(100vh - 270px);
	align-content:flex-start;
	overflow:auto;
}
.main .maincouriers .maincourierslist ul {
	width:98%;
	margin:0;
	padding:0;
	list-style:none;
}
.main .maincouriers .maincourierslist ul li {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	padding:10px 15px;
	border-bottom:1px solid #eee;
	cursor:pointer;
}
.main .maincouriers .maincourierslist ul li div {
	width:100%;
}
.main .maincouriers .maincourierslist ul li .oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.main .maincouriers .maincourierslist ul li .onlinestatus {
	width:6px;
	height:6px;
	border-radius:50%;
	margin-right:5px;
}
.main .maincouriers .maincourierslist ul li .onlinestatus.offline {
	background:#c00;
}
.main .maincouriers .maincourierslist ul li .onlinestatus.online {
	background:#278245;
}
.main .maincouriers .maincourierslist ul li .phone {
	font-size:80%;
	font-weight:400;
	color:#777;
}
.main .maincouriers .maincourierslist ul li .courierinfo {
	margin-top:5px;
	padding:5px;
	background:#f2f2f2;
	border-radius:5px;
}
.main .maincouriers .maincourierslist ul li .courierinfo div {
	font-size:80%;
}
.promptcontainerblock .promptcontainercash {
	display:block;
}
.promptcontainercash div {
	display:block;
	margin:0;
}
.promptcontainercash .title {
	font-size:110% !important;
	font-weight:600;
}
.promptcontainercash .money {
	margin-bottom:10px;
	font-size:120%;
	font-weight:700;
}
.promptcontainercash .titlereport {
	font-size:110%;
	font-weight:500;
}
.promptcontainercash .moneyreport {
	font-size:110%;
	font-weight:600;
	text-align:right;
}
.promptcontainercash .moneyreport span {
	display:block;
	font-weight:normal;
	font-size:90%;
}
.promptcontainercash input {
	font-weight:700;
	font-size:200%;
	padding:10px 15px;
	letter-spacing:unset;
}
.promptcontainercash ul {
	display:flex;
	flex-direction:row;
	align-items:center;
	margin-top:15px;
	margin-bottom:20px;
}
.promptcontainercash ul li {
	padding:5px 10px;
	margin-right:10px;
	border-radius:5px;
	background:#eee;
	cursor:pointer;
}
.promptcontainercash ul li.selected {
	background:#555;
	color:#fff;
}
.promptcontainercash .control {
	margin-top:20px;
}
.promptcontainercash .title, .promptcontainerblock .nums .title {
	margin-bottom:0;
	font-size:120%;
	font-weight:normal;
}
.promptcontainerblock .nums .title {
	margin-bottom:10px;
}
.promptcontainerblock .nums {
	padding:20px;
	width:calc(50% - 10px);
}
.promptcontainerblock .cancelinput {
	flex-direction:column;
	align-items:flex-start;
	justify-content:flex-start;
}
.promptcontainerblock .cancelinput p {
	margin-bottom:20px;
	font-size:130%;
}
.promptcontainerblock .cancelinput input {
	display:block;
	width:100%;
	font-size:140%;
	padding:10px 15px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.promptcontainerblock .cancelinput input:focus {
    background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}
.promptcontainerblock .cancelinput label {
	display:flex;
	flex-direction:row;
	align-items:center;
	font-size:120%;
	margin-top:20px;
}
.promptcontainerblock .cancelinput input[type=checkbox] {
	width:20px !important;
	height:20px !important;
	margin-right:10px;
}
.promptcontainerblock .input {
	padding:10px;
	width:440px;
	height:60px;
	border:none;
	font-size:28px;
	margin-bottom:10px;
	padding-left:175px;
	background:#fff;
	border:2px solid #aaa;
}
.promptcontainerblock .focus {
	border-color:rgba(0,0,255) !important;
	box-shadow:0 1px 1px rgba(0,0,0,.075) inset, 0 0 8px rgba(0,0,255,.6) !important;
	outline:0 none !important;
}
.promptcontainerblock .keypad {
	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-wrap:wrap;
	margin:20px 0 10px;
}
.promptcontainerblock .keypad .num {
	width:92px;
	height:60px;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-bottom:5px;
	font-size:32px;
	font-weight:700;
	color:#fff;
	background:#555;
	cursor:pointer;
}
.promptcontainerblock .keypad .num:active {
	background:#ccc;
	color:#333;
}
.promptcontainerblock .keypad .delete {
	font-size:24px;
}
.promptcontainerblock .keypad .numlast {
	margin:0;
}