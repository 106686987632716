.container h4 {
	display:flex;
	align-items:center;
	justify-content:space-between;
}
.container h4 span a {
	margin-right:10px;
}
.container h4 a.add {
	display:flex;
	align-items:center;
	border:2px solid #000;
	font-size:15px;
	font-weight:bold;
	color:#000;
	text-decoration:none;
	padding:2px 12px 2px 6px;
	border-radius:4px;
}
.container h4 a.add:hover {
	border-color:#999;
	background:rgba(255,255,255,.8);
	color:#00000080;
}
.container h4 a.add:before {
	content:'';
	display:block;
	width:24px;
	height:24px;
	margin-right:4px;
	background:url('../../../Images/icons/add.svg') 0 0 no-repeat;
}
.container h4 a.add:hover:before {
	opacity:.5;
}
.formcontainer {
	margin-top:20px;
	margin-bottom:100px;
    padding:20px;
    border-radius:4px;
    box-shadow:0 2px 2px rgba(204,197,185,.5);
    background:#fff;
    position:relative;
    z-index:1;
    zoom:1;
    overflow:hidden;
}
.formcontainerclean {
	padding:0;
	box-shadow:none;
	background:none;
}
.formcontainer form {
	max-width:900px;
}
.formcontainer .cell {
	margin:0 20px 20px 0;
}
.formcontainer .cell label span {
	display:block;
	margin:0 0 5px 0;
	color:#777;
}
.formcontainer .cell .cellbutton {
	margin:10px 0;
}
.formcontainer .cell .cellbutton .button {
	padding:5px 15px 7px;
	background:#333;
	color:#fff;
	border:none;
	border-radius:3px;
}
.formcontainer .cell .cellbutton .button:hover {
	opacity:.8;
	cursor:pointer;
}
.formcontainer input, .formcontainer select, .formcontainer textarea {
	display:block;
	width:200px;
	padding:10px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.formcontainer input:focus, .formcontainer select:focus, .formcontainer textarea:focus {
    background-color: #fff;
    box-shadow: none;
    outline: 0 !important;
}
.formcontainer input[type=checkbox] {
	width:auto;
	display:inline-block;
}
.formcontainer input.double {
	width:500px;
}
.formcontainer textarea {
	width:685px;
}
.formcontainer textarea.desc {
	height:100px;
	resize:none;
}
.oneline, .onelinefull {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.onelinefull .cell {
	width:33%;
}
.onelinefulltop {
	align-items:flex-start;
}
.notice {
	margin-top:5px;
	font-size:90%;
	color:#aaa;
}
.buttons {
	position: fixed;
	bottom:0;
	width:100%;
	padding:20px 10px 30px;
	margin-left:-30px;
	border-top:1px solid #ccc;
	display:flex;
	flex-direction:row;
	align-items:center;
	background:#f4f3ef;
}
.buttons button {
	width:160px;
	height:40px;
	background:#333;
	color:#fff;
	font-size:16px;
	border:none;
	border-radius:3px;
	margin-left:5px;
	margin-right:20px;
}
.buttons button:hover {
	opacity:.8;
	cursor:pointer;
}
.buttons button[disabled] {
	background:#333 url(../../../Images/loading.svg) 50% 50% no-repeat;
}
.link {
	text-decoration:underline;
	color:#007ab1;
	cursor:pointer;
}
.container .linepositions {
	margin:10px 0 20px;
	padding-top:20px;
	border-top:1px solid #ccc;
}
.container h4 a.plus {
	display:block;
	width:26px;
	height:26px;
	background:url('../../../Images/icons/add.svg') 50% 50% no-repeat;
	cursor:pointer;
}
.container h4 a.plus:hover {
	opacity:.5;
}
.modalcontainer {
	height:100%;
	padding:20px;
	position:relative;
}
.modalcontainer h4.title {
	margin:10px 0 20px;
}
.modalcontainer .onelinefull .cell {
	width:200px;
}
.modalcontainer .products {
	position:relative;
	height:calc(100% - 85px);
	overflow:auto;
	padding:0 0 5px 0;
}
.modalcontainer .products ul {
	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-wrap:wrap;
}
.modalcontainer .products ul li {
	width:33%;
	padding:5px 2px;
}
.modalcontainer .products ul li label input[type=radio] {
	width:auto;
	display:inline-block;
}
.modalcontainer .products ul li:hover {
	background:#eee;
}
.modalcontainer .products ul span {
	display:block;
	color:#aaa;
	margin:-5px 0 0 25px;
	font-size:80%;
}
.modalcontainer .search {
	margin:10px 0;
}
.modalcontainer .search input {
	width:100%;
	padding:6px;
}
.modalcontainer .control {
	margin:20px 0 0;
}
.modalcontainer .control .button {
	width:160px;
	height:40px;
	background:#333;
	color:#fff;
	font-size:16px;
	border:none;
	border-radius:3px;
}
.modalcontainer .control .button:hover {
	opacity:.8;
	cursor:pointer;
}
.container .linepositions table {
	width:100%;
	margin:10px 0;
}
.container .linepositions table thead tr {
	background:#07385a;
	color:#fff;
	text-align:left;
}
.container .linepositions table th, .container .linepositions table td {
	padding:4px 8px;
}
.container .linepositions table tbody tr:nth-child(even) {
	background:#f4f3ef;
}
.container .linepositions table td a {
	color:#000;
	cursor:pointer;
}