.container h4 {
	display:flex;
	align-items:center;
	justify-content:space-between;
}
.formcontainer {
	margin-top:20px;
	margin-bottom:100px;
    padding:20px;
    border-radius:4px;
    box-shadow:0 2px 2px rgba(204,197,185,.5);
    background:#fff;
    position:relative;
    z-index:1;
    zoom:1;
    overflow:hidden;
}
.formcontainer form {
	max-width:900px;
}
.formcontainer .cell {
	margin:0 20px 20px 0;
}
.formcontainer .cell label span {
	display:block;
	margin:0 0 5px 0;
	color:#777;
}
.formcontainer input, .formcontainer select {
	display:block;
	width:200px;
	padding:10px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.formcontainer select {
	width:auto;
}
.formcontainer input:focus, .formcontainer select:focus {
    background-color: #fff;
    box-shadow: none;
    outline: 0 !important;
}
.container .linepositions {
	margin:30px 0 20px;
	padding-top:20px;
	border-top:1px solid #ccc;
}
.container .linepositions table {
	width:100%;
	margin:10px 0;
}
.container .linepositions table thead tr {
	background:#07385a;
	color:#fff;
	text-align:left;
}
.container .linepositions table th, .container .linepositions table td {
	padding:4px 8px;
}
.container .linepositions table tbody tr:nth-child(even) {
	background:#f4f3ef;
}
.container .linepositions table td a {
	color:#000;
	cursor:pointer;
}
.container .productslist {
	max-width:900px;
	margin:20px 0;
}
.container .productslist li {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:flex-start;
	padding:5px;
	border-bottom:1px dashed #ccc;
}
.container .productslist li:last-child {
	border:none;
}
.container .productslist li .name {
	width:100%;
}
.container .productslist li .measure {
	color:#999;
	width:200px;
	text-align:right;
}