.container {
	width:100%;
	position:absolute;
	top:0;
	background:#fffcc0;
	padding:15px 0;
	z-index:10001;
	box-shadow:0 1px 5px rgb(37,48,62,.2);
}
.container .inner {
	width:1000px;
	margin:0 auto;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:flex-end;
}
.container .inner h4 {
	margin-bottom:5px;
}
.container button {
	height:40px;
	padding:0 40px;
	font-size:100%;
    border:none;
    border-radius:5px;
    background:#000;
	color:#fff;
    cursor:pointer;
	transition:.4s;
	box-shadow:0 2px 5px rgb(37,48,62,.2);
}