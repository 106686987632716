.container {
	width:100%;
	display:flex;
	justify-content:space-between;
	flex-direction:row;
	overflow:hidden;
	flex-wrap:wrap;
}
.loading {
	margin:40px 0 0 30px;
}
.main {
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
	align-content:flex-start;
	justify-content:flex-start;
	width:1024px;
	height:calc(100vh - 85px);
	margin:10px auto 0;
	padding-bottom:20px;
	overflow:auto;
}
.main .item {
	width:325px;
	max-height:200px;
	padding:20px;
	background:#f8f8f8;
	padding:20px;
	margin:5px;
	border-radius:3px;
	box-shadow:0 1px 2px rgba(37,48,62,.2);
	cursor:pointer;
	transition:.4s;
	overflow:hidden;
}
.main .item .inneritem {
	width:100%;
	max-height:160px;
	overflow:hidden;
}
.main .item.alarm {
	background:rgba(204,0,0,.2);
}
.main .item.paid {
	background:rgba(0,204,0,.2);
}
.main .item:hover {
	opacity:.9;
}
.main .item .block {
	display:flex;
	justify-content:space-between;
	align-items:flex-start;
	margin-bottom:10px;
}
.main .item .block:first-child {
	align-items:center;
}
.main .item .number {
	font-size:110%;
	margin-bottom:5px;
	font-weight:bold;
}
.main .item .products {
	margin:10px 0 10px;
	font-size:90%;
}
.main .item .status {
	font-size:80%;
	padding:0 6px;
	border-radius:3px;
	color:#555;
	background:#eee;
}
.main .empty {
	margin:10px 5px;
	font-weight:bold;
}
.modalcontainer .header {
	padding:25px 20px 10px 20px;
	border-radius:5px 5px 0 0;
	background:#f2f2f2;
}
.modalcontainer .header h4 {
	margin-bottom:10px;
}
.modalcontainer .productslist {
	height:356px;
	padding:10px 20px 0;
	overflow:auto;
}
.modalcontainer .productslist.nobutton {
	height:416px;
}
.modalcontainer .productslist .item {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	padding:10px 5px;
	border-bottom:1px solid #ccc;
}
.modalcontainer .productslist .item .name {
	width:100%;
}
.modalcontainer .productslist .item .measure {
	color:#999;
	width:80px;
}
.modalcontainer .productslist .item .statusDone {
	opacity:.2;
}
.modalcontainer .productslist .item .status {
	width:140px;
	text-align:center;
	font-weight:bold;
}
.modalcontainer .productslist .item .status div {
	padding:7px;
}
.modalcontainer .productslist .item .status button {
	width:100%;
	background:#67ac5b;
	color:#fff;
	border:none;
	padding:10px;
	cursor:pointer;
	transition:.4s;
	border-radius:3px;
	text-transform:uppercase;
}
.modalcontainer .done {
	padding:10px;
    background:#f2f2f2;
	border-radius:0 0 5px 5px;
	border-top:1px solid #ccc;
}
.modalcontainer .done img {
	width:20px;
	margin-right:10px;
	filter:invert(1);
}
.modalcontainer .done button {
    padding:10px 20px;
    background:#333;
    color:#fff;
    border:none;
    border-radius:3px;
	margin-left:10px;
	text-transform:uppercase;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
}
.areaactive {
	position:absolute;
	top:20px;
	left:45%;
	display:flex;
	align-items:center;
}
.areaactive button {
	display:flex;
	align-items:center;
	margin-right:20px;
	font-size:100%;
	color:#333;
	border:none;
	padding:8px 14px;
	transition:.4s;
	border-radius:5px;
}
.areaactive button img {
	width:18px;
	margin-right:5px;
}