.container h4 {
	margin:0 0 15px 10px;
}
.formcontainer {
	margin-top:20px;
	margin-bottom:100px;
	padding:20px;
	border-radius:4px;
	box-shadow:0 2px 2px rgba(204,197,185,.5);
	background:#fff;
	position:relative;
	z-index:1;
	zoom:1;
	overflow:hidden;
}
.container .controls {
	display:flex;
	justify-content:flex-start;
	align-items:center;
	margin:20px 0;
}
.container .controls select {
	margin-right:20px;
	padding:8px 10px;
	border:1px solid #ccc;
	border-radius:5px;
}
.container .empty {
	margin-bottom:30px;
}
.container .loading {
	margin-bottom:30px;
}
.container .reportcontainer {
	margin-top:15px;
	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-wrap:wrap;
}
.container .reportcontainer .row {
	width:calc(50% - 10px);
	margin:0 5px 15px;
	padding:5px 10px;
	background:#f8f8f8;
}
.container .reportcontainer .row:last-child {
	border:none;
}
.container .reportcontainer .row .title {
	font-weight:bold;
}
.container .reportcontainer .row .cell {
	display:flex;
	align-items:center;
	flex-direction:row;
	margin:0;
	padding:0;
}
.container .reportcontainer .row .cell span:first-child {
	display:block;
	width:200px;
}