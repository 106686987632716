.container h4 {
	display:flex;
	align-items:center;
	justify-content:space-between;
}
.container h4 span a {
	margin-right:10px;
}
.container h4 a.add {
	display:flex;
	align-items:center;
	border:2px solid #000;
	font-size:15px;
	font-weight:bold;
	color:#000;
	text-decoration:none;
	padding:2px 12px 2px 6px;
	border-radius:4px;
}
.container h4 a.add:hover {
	border-color:#999;
	background:rgba(255,255,255,.8);
	color:#00000080;
}
.container h4 a.add:before {
	content:'';
	display:block;
	width:24px;
	height:24px;
	margin-right:4px;
	background:url('../../Images/icons/add.svg') 0 0 no-repeat;
}
.container h4 a.add:hover:before {
	opacity:.5;
}
.formcontainerouter {
	margin-bottom:100px;
}
.formcontainer {
	margin-top:20px;
	margin-bottom:10px;
	padding:20px;
	border-radius:4px;
	box-shadow:0 2px 2px rgba(204,197,185,.5);
	background:#fff;
	position:relative;
	z-index:1;
	zoom:1;
	overflow:hidden;
}
.formcontainer form {
	max-width:900px;
}
.formcontainer .cell {
	margin:0 20px 20px 0;
}
.formcontainer .cell label span {
	display:block;
	margin:0 0 5px 0;
	color:#777;
}
.formcontainer .cell label span.clientphone {
	white-space:nowrap;
}
.formcontainer input, .formcontainer select, .formcontainer textarea {
	display:block;
	width:215px;
	padding:10px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.formcontainer input:focus, .formcontainer select:focus, .formcontainer textarea:focus {
    background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}
.formcontainer input:disabled {
	background:#eee url('../../Images/icons/lock.svg') 98% 50% no-repeat;
	background-size:20px;
}
.formcontainer textarea {
	width:685px;
}
.formcontainer textarea.desc {
	height:100px;
	resize:none;
}
.formcontainer h4.title {
	margin-bottom:20px;
}
.oneline, .onelinefull {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.onelinefull {
	justify-content:space-between;
}
.notice {
	margin-bottom:10px;
	padding:10px;
	background:#fffcc0;
}
.buttons {
	position:fixed;
	bottom:0;
	width:100%;
	padding:20px 10px 30px;
	margin-left:-30px;
	border-top:1px solid #ccc;
	display:flex;
	flex-direction:row;
	align-items:center;
	background:#f4f3ef;
	z-index:100;
}
.buttons button {
	width:160px;
	height:40px;
	background:#333;
	color:#fff;
	font-size:16px;
	border:none;
	border-radius:3px;
	margin-left:5px;
	margin-right:20px;
}
.buttons button:hover {
	opacity:.8;
	cursor:pointer;
}
.buttons button[disabled] {
	background:#333 url(../../Images/loading.svg) 50% 50% no-repeat;
}
.container table {
	width:100%;
}
.container table thead tr {
	background:#07385a;
	color:#fff;
	text-align:left;
}
.container table th, .container table td {
	padding:4px 8px;
}
.container table tbody tr:nth-child(even) {
	background:#f4f3ef;
}
.container table td a {
	color:#000;
	cursor:pointer;
}
.modalcontainer {
	height:100%;
	padding:20px;
	position:relative;
}
.container h4 a.plus, .productadd {
	display:block;
	width:26px;
	height:26px;
	background:url('../../Images/icons/add.svg') 50% 50% no-repeat;
	cursor:pointer;
}
.container h4 a.plus:hover, .productadd:hover {
	opacity:.5;
}
.modalcontainer h4.title {
	margin:10px 0 20px;
}
.map {
	position:relative;
	width:100%;
	height:340px;
	background:#eee url('../../Images/loading.svg') 50% 50% no-repeat;
}
.centerMarker {
    width:40px;
    height:40px;
    background:url('../../Images/icons/marker.svg') 0 0 no-repeat;
    position:absolute;
    left:50%;
    margin-left:-20px;
    top:50%;
    margin-top:-20px;
}
.addressinput {
	margin:10px 0 10px;
}
.addressinput input {
	display:block;
	width:100%;
	padding:10px;
	margin-bottom:10px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.addressinput input:focus {
    background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}
.addressinput .inputs {
	display:flex;
	flex-direction:row;
	align-items:flex-start;
}
.addressinput .inputs input {
	display:block;
	width:140px;
	margin-top:5px;	
	margin-right:20px;
}
.addressinput .inputs input[type=checkbox] {
	width:auto;
}
.addresses {
	position:absolute;
	width:calc(100% - 40px);
	height:200px;
	margin-top:5px;
	overflow-y:scroll;
	background:#fff;
	z-index:10;
	box-shadow:0 5px 15px rgba(37,48,62,.2);
	border-radius:3px;
}
.addresses div {
	padding:5px 10px;
	border-bottom:1px solid #eee;
}
.addresses div:last-child {border-bottom:none;}
.addresses div span {
	display:block;
	color:#999;
	font-size:80%;
}
.addresses div:hover {
	background:#f8f8f8;
	cursor:pointer;
}
.modalbuttons {
	margin-top:10px;
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:flex-end;
}
.modalbuttons button {
	margin-left:20px;
	padding:10px 20px;
	background:#333;
	color:#fff;
	font-size:14px;
	border:none;
	border-radius:3px;
}
.modalbuttons button:hover {
	opacity:.8;
	cursor:pointer;
}
.ordercontainer, .orderviewcontainer {
	height:480px;
	overflow:auto;
}
.orderviewcontainer {
	height:490px;
}
.ordercontainer input, .orderviewcontainer input {
	display:block;
	width:215px;
	padding:5px;
	margin-right:20px;;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.ordercontainer input:focus, .orderviewcontainer input:focus {
    background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}
.clientorderheader {
	display:flex;
	flex-direction:row;
	align-items:flex-start;
	border-bottom:1px solid #ddd;
	margin-bottom:10px;
}
.clientorderheader .cell {
	margin:0 40px 10px 0;
}
.clientorderheader .cell label span {
	display:block;
	margin:0 0 2px 0;
	color:#777;
}
.clientorderheader select {
	display:block;
	width:215px;
	padding:5px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.clientorderheader select:focus {
    background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}
.productscontainer {
	background:#f8f8f8;
	padding:10px;
	margin:10px 0;
	overflow:auto;
	height:200px;
}
.productscontainer ul li {
	display:flex;
	align-items:center;
	justify-content:space-between;
	padding:5px;
	margin-bottom:5px;
	border-bottom:1px solid #e5e5e5;
}
.productscontainer ul li img {
	width:16px;
	opacity:.7;
}
.productscontainer ul li img:hover {
	cursor:pointer;
	opacity:1;
}
.summary, .summaryview {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	margin-top:20px;
	padding:10px;
	background:#f8f8f8;
}
.summaryview {
	margin:0 0 10px 0;
}
.summary .summaryblock, .summaryview .summaryblock {
	width:30%;
}
.summary .summaryblock .hint, .summaryview .summaryblock .hint {
	display:block;
	font-size:80%;
	color:#999;
}
.productsfilter {margin:10px 0;}
.productsfilter input {
	width:100%;
	padding:8px 10px;
	border:1px solid #ccc;
	border-radius:5px;
}
.productitemscontainer {
	height:670px;
	overflow:auto;
	background:#f2f2f2;
}
.productitems {
	display:flex;
	flex-wrap:wrap;
}
.productitem {
	display:flex;
	flex-direction:column;
	width:calc(33% - 20px);
	padding:10px;
	margin:10px;
	background:#fff;
	border-radius:4px;
	justify-content:space-between;
}
.productitem:hover {
	cursor:pointer;
	box-shadow:0 5px 20px 0 rgba(0,0,0,.1);
}
.productitem .producttitle {
	font-size:18px;
}
.productitem .producttitle span {
	display:block;
	font-size:80%;
	color:#777;
	margin-top:-2px;
}
.productitem img {
	height:140px;
	width:100%;
	margin:5px 0 10px;
	border-radius:4px;
	object-fit:cover;
}
.productitem .productdesc {
	color:#b0b0b0;
}
.productitem .productheader {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
}
.productitem .productprice {
	margin:3px 0 0 10px;
	white-space:nowrap;
	font-size:16px;
}
.emptyproducts {
	display:flex;
	margin-top:30px;
	justify-content:center;
	align-items:center;
	flex-direction:column;
}
.emptyproducts p {
	margin-bottom:30px;
}
.emptyproducts .buttonadd {
	width:140px;
	text-align:center;
	border:2px solid #000;
	font-size:15px;
	font-weight:bold;
	color:#000;
	text-decoration:none;
	padding:6px 12px;
	border-radius:4px;
	cursor:pointer;
}
.emptyproducts .buttonadd:hover {
	border-color:#999;
	background:rgba(255,255,255,.8);
	color:#00000080;
}
.gotoorders {
	color:#555;
	padding:12px 20px;
	background:#eee;
	border-radius:4px;
	text-decoration:none;
	cursor:pointer;
}
.gotoorders:hover {
	color:#000;
	background:#f2f2f2;
}
.link {text-decoration:underline;}