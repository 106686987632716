.container h4 {
	margin:0 0 15px 10px;
}
.formcontainer {
	margin-top:20px;
	margin-bottom:30px;
	padding:20px;
	border-radius:4px;
	box-shadow:0 2px 2px rgba(204,197,185,.5);
	background:#fff;
	position:relative;
	z-index:1;
	zoom:1;
	overflow:hidden;
}
.container h3 {
	margin-bottom:20px;
}
.license {
	margin-top:10px;
}
.version {
	margin-top:10px;
}