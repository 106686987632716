.container h4 {
	display:flex;
	align-items:center;
	justify-content:space-between;
	margin-bottom:20px;
}
.container h4 a {
	display:flex;
	align-items:center;
	border:2px solid #000;
	font-size:15px;
	font-weight:bold;
	color:#000;
	text-decoration:none;
	padding:2px 12px 2px 6px;
	border-radius:4px;
}
.container h4 a:hover {
	border-color:#999;
	background:rgba(255,255,255,.8);
	color:#00000080;
}
.container h4 a:before {
	content:'';
	display:block;
	width:24px;
	height:24px;
	margin-right:4px;
	background:url('../../Images/icons/add.svg') 0 0 no-repeat;
}
.container h4 div.search a:before {
	background:url('../../Images/icons/search.svg') 0 0 no-repeat;
}
.container h4 a:hover:before {
	opacity:.5;
}
.container .list {
	margin:0 0 20px 0;
}
.container .controls {
	margin-bottom:10px;
	display:flex;
	justify-content:space-between;
}
.container .controls div span {
	color:#555;
	margin-right:10px;
}
.container .controls input, .container .controls select {
	padding:8px 10px;
	border:1px solid #ccc;
	border-radius:5px;
}
.container .controls input {
	width:400px;
}
.container table {
	width:100%;
	border-collapse:collapse;
}
.container table thead tr {
	text-align:left;
}
.container table thead th {
	padding:10px;
	white-space:nowrap;
	border-bottom:3px solid #ccc;
	background:#333;
	color:#fff;
	position:sticky;
	top:0;
}
.container table tbody:before {
    content:"@";
    display:block;
    line-height:5px;
    text-indent:-99999px;
}
.container table tbody td {
	padding:15px 10px;
}
.container table tbody tr {
	background:#fff;
	border-bottom:5px solid #f4f3ef;
}
.container table td a {
	color:#000;
}
.container td .img {
	width:50px;
}




.statusOnline, .statusOffline {
	display: block;
	font-size: 12px;
	width: fit-content;
	padding: 2px 4px;
	border-radius: 5px;
	font-weight: bold;
}
.statusOnline {
	background-color: #27ae60;
	color: white;
}
.statusOffline {
	background-color: #bdc3c7;
	color: #57606f;
}