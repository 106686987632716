.sidemenu {
	width:100vw;
	height:100vh;
}
.sidemenu .inner {
	position:relative;
	width:1024px;
	margin:0 auto;
	padding:30px;
}
.sidemenu .inner div span {
	display:block;
	color:#999;
}
.sidemenu .menucontainer {
	width:1024px;
	margin:0 auto;
	padding:30px;
}
.sidemenu ul {
	margin:20px 0;
}
.sidemenu ul li {
	font-size:120%;
}
.sidemenu ul li a {
	text-decoration:none;
	color:#555;
}
.sidemenu ul li:hover a {
	display:inline-block;
	color:#000;
	background:#eee;
}
.sidemenu .logoff {
	margin-top:50px;
}
.sidemenu .logoff a {
	font-size:100%;
	color:#777;
	text-decoration:none;
}
.logo {
	margin-bottom:50px;
}
.logo img {
	height:80px;
}
.footer {
	position:absolute;
	left:50%;
	bottom:10px;
	width:1024px;
	margin-left:-512px;
	padding-left:30px;
	font-size:90%;
	display:flex;
	align-items:center;
}
.footer, .footer a {
	color:#ccc;
	text-decoration:none;
}
.footer span {
	margin:0 5px;
	font-size:150%;
}
.sidemenuside {
	width:240px;
	height:100%;
	background:#fff;
}
.sidemenuside .menucontainer {
	position:fixed;
	width:240px;
	height:100%;
	padding:10px 20px 0;
	overflow:auto;
}
.sidemenuside .menucontainer img {
	margin-bottom:10px;
}
.sidemenuside ul {
	margin:5px 0 10px;
}
.sidemenuside ul li {
	padding:1px 0;
}
.sidemenuside ul li a {
	display:block;
	text-decoration:none;
	color:#555;
}
.sidemenuside ul li:hover a {
	color:#000;
	background:#eee;
}
.sidemenuside .logoff {
	margin:20px 0;
}
.sidemenuside .logoff a {
	font-size:100%;
	color:#777;
	text-decoration:none;
}