.container {
	width:100%;
	display:flex;
	justify-content:space-between;
	flex-direction:row;
	overflow:hidden;
	flex-wrap:wrap;
}
.loading {
	margin:40px 0 0 30px;
}
.main {
	display:flex;
	justify-content:space-between;
	align-items:flex-start;
	width:1024px;
	margin:10px auto 0;
	overflow:hidden;
}
.statlast {
	width: 300px;
}
.main .column {
	width:33%;
	margin:5px;
}
.main .column .header {
	text-transform:uppercase;
	font-weight:600;
	color:#fff;
	margin-bottom:10px;
	padding:10px;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	border-radius:3px;
	box-shadow:0 1px 2px rgba(37,48,62,.2);
	background:#c00;
}
.main .column .header.info {
	background:#999;
}
.main .column .header.order {
	position:sticky;
	top:0;
	background:#369;
	z-index:2;
}
.main .column .header img {
	width:22px;
	filter:invert(1);
}
.main .column .container {
	display:block;
	width:100%;
	height:calc(100vh - 140px);
	overflow:auto;
}
.main .column .containerinfo {
	display:block;
	width:100%;
	overflow-y:scroll;
}
.main .column .containerinfo::-webkit-scrollbar {
	width: 5px;
}
.main .column .containerinfo::-webkit-scrollbar-button {
	 background: #ccc
 }
.main .column .containerinfo::-webkit-scrollbar-track-piece {
	background: #eee
}
.main .column .containerinfo::-webkit-scrollbar-thumb {
	background: #888
}

.container .cell {
	margin-bottom: 20px;
}

.container .cell a {
	color: #2980b9;
}
.container .cell a:hover {
	color: #34495e;
	text-decoration: underline;
}
.container .cell a:active {
	color: #3498db;
}

.main .column .item {
	position:relative;
	padding:10px;
	margin:0 0 10px;
	border-radius:3px;
	background:#fff;
	box-shadow:0 1px 2px rgba(37,48,62,.2);
	cursor:pointer;
	border:2px solid transparent;
}
.main .column .itemwide {
	border:none;
	border-radius:0;
	box-shadow:none;
}
.main .column .alarm {
	background:rgba(204,0,0,.2);
}
.main .column .paid {
	background:rgba(0,204,0,.2);
}
.main .column .selected {
	border:2px solid #555;
}
.main .column .item .oneline {
	display:flex;
	justify-content:space-between;
	align-items:center;
}
.main .column .item .amount {
	font-size:120%;
	font-weight:bold;
	background:#eee;
	padding:2px 6px;
	border-radius:3px;
	white-space:nowrap;
}
.main .column .item .products {
	margin:20px 0 10px;
	font-size:90%;
}
.main .column .item .num {
	margin-bottom:10px;
}
.main .column .item .date {
	color:#999;
}
.main .column .item .status {
	font-size:90%;
	padding:0 6px;
	border-radius:3px;
	background:#eee;
}
.main .column .item .status.busy {
	font-size:90%;
	padding:0 6px;
	border-radius:3px;
	background:#c00;
	color:#fff;
}
.main .column .empty {
	margin:10px 5px;
	font-weight:bold;
}
.main .column .button {
	font-size:20px;
	color:#fff;
	margin-bottom:10px;
	height:120px;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	border-radius:3px;
	box-shadow:0 1px 2px rgba(37,48,62,.2);
	background:#aaa;
}
.main .column .button.empty {
	margin:0 0 10px 0;
	background:#e0e0e0;
	box-shadow:none;
}
.main .column .button div {
	position:relative;
	margin:0 auto;
	text-align:center;
}
.main .column .button img {
	display:block;
	width:40px;
	margin:0 auto 10px;
}
.main .column .button div .icomingcount {
	position:absolute;
	width:20px;
	top:-2px;
	left:105px;
}
.main .column .button.ordercallactive {
	background:#c00;
}
.main .column .button.orderhold {
	background:#777;
}
.main .column .button.orderadd {
	background:#6c42a6;
}
.main .column .button.ordersearch {
	background:#146f84;
}
.main .column .button.couriers {
	background:#278245;
}
.main .column .button.orders {
	background:#333;
}
.main .column .button.ordersmap {
	background:#664a17;
}
.main .column .button.ordercall,
.main .column .button.ordercallactive,
.main .column .button.orderhold,
.main .column .button.orderadd,
.main .column .button.couriers,
.main .column .button.orders,
.main .column .button.ordersmap,
.main .column .button.ordersearch {
	cursor:pointer;
}
.orderinfocontainer {
	width:calc(66% - 5px);
	height:calc(100vh - 85px);
	margin-top:5px;
	overflow:hidden;
	background:#fff;
	border-radius:3px 3px 0 0;
}
.orderinfocontainer .header {
	position:relative;
	padding:20px 10px;
	background:#fff;
	border-bottom:1px solid #ccc;
}
.orderinfocontainer .header h4 .status {
	position:relative;
	top:-2px;
	font-size:80%;
	font-weight:normal;
	margin-left:15px;
	padding:2px 8px 3px;
	color:#fff;
	border-radius:3px;
}
.orderinfocontainer .header h4 .status.statusnew {
	background:#999;
}
.orderinfocontainer .header h4 .status.statuspaid {
	background:#690;
}
.orderinfocontainer .orderbody {
	height:calc(100vh - 210px);
	padding:20px 10px;
	overflow:auto;
}
.orderbody .sections {
	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-direction:row;
}
.orderbody .sections .section {
	width:25%;
	height:100px;
	display:flex;
	justify-content:center;
	align-items:center;
	flex-direction:column;
	color:#fff;
	font-size:120%;
	margin:5px;
	cursor:pointer;
}
.orderbody .sections .section.rolls {
	background:#82cdde;
}
.orderbody .sections .section.burgers {
	background:#9ac0e1;
}
.orderbody .sections .section.shashlik {
	background:#8193ca;
}
.orderbody .sections .section.napitki {
	background:#a386be;
}
.orderbody .sections .section.wok {
	background:#369;
}
.orderbody .sections .section.minirolls {
	background:#146f84;
}
.orderbody .sections .section img {
	display:block;
	width:30px;
}
.orderbody .products {
	display:flex;
	align-items:center;
	flex-direction:row;
	flex-wrap:wrap;
}
.orderbody .products .productitem {
	width:calc(25% - 10px);
	height:120px;
	display:flex;
	justify-content:center;
	align-items:center;
	flex-direction:column;
	font-size:110%;
	margin:5px;
	padding:5px;
	text-align:center;
	background:#eee;
	cursor:pointer;
}
.orderbody .products .productitem span {
	display:block;
	margin-top:5px;
	font-size:90%;
}
.orderbody .products .productitem.cat0 {
	background:#9ac0e1;
}
.orderbody .products .productitem.cat1 {
	background:#9ac0e1;
}
.orderbody .products .productitem.cat2 {
	background:#9ac0e1;
}
.orderbody .products .productitem.cat3 {
	background:#8193ca;
}
.orderbody .products .productitem.cat4 {
	background:#a386be;
}
.orderbody .products .productitem.cat5 {
	background:#9ac0e150;
}
.orderbody .products .productitem.cat6 {
	background:#a386be50;
}
.orderbody .categories {
	display:flex;
	align-items:center;
	flex-direction:row;
	flex-wrap:wrap;
}
.orderbody .categories .catitem {
	width:calc(25% - 10px);
	height:100px;
	display:flex;
	justify-content:center;
	align-items:center;
	flex-direction:column;
	font-size:120%;
	color:#fff;
	margin:5px;
	text-align:center;
	background:#eee;
	cursor:pointer;
}
.orderbody .categories .catitem img {
	display:block;
	width:30px;
}
.orderbody .categories .catitem span {
	display:block;
	margin-top:5px;
	font-size:90%;
}
.orderbody .categories .catitem.cat {
	background:#82cdde;
}
.orderbody .categories .catitem.back {
	color:#333;
}
.ordercallcontainer {
	width:calc(66% - 5px);
	height:calc(100vh - 85px);
	margin-top:5px;
	overflow:hidden;
	background:#fff;
	border-radius:3px 3px 0 0;
}
.ordercallcontainer .header {
	position:relative;
	padding:20px 10px;
	background:#fff;
	border-bottom:1px solid #ccc;
}
.ordercallcontainer .header h4 {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.ordercallcontainer .orderbody {
	height:calc(100vh - 210px);
	padding:20px 10px;
	overflow:auto;
}
.ordercallcontainer .orderbodyclient {
	height:calc(100vh - 145px);
}
.ordercallcontainer .clientinfo .cell {
	margin:0 10px 10px 0;
}
.clientinfo .cell label span {
	display:block;
	margin:0 0 2px 0;
	color:#777;
	white-space:nowrap;
}
.clientinfo .cell label input {
	display:block;
	width:100%;
	padding:7px 10px;
	font-size:110%;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.clientinfo .cell select {
	display:block;
	width:100%;
	padding:7px 10px;
	font-size:110%;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.clientinfo .cell select option {
	font-size:110%;
}
.clientinfo .cell select:focus {
    background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}
.clientinfo .cell button {
	margin-top:10px;
	padding:10px 20px;
	background:#333;
	color:#fff;
	border:none;
	border-radius:3px;
	text-transform:uppercase;
	text-align:center;
}
.clientinfo .cell button:hover {
	opacity:.8;
	cursor:pointer;
}
.clientinfo .cell button.add {
	margin-left:20px;
	color:#333;
	padding:8px 18px;
	font-weight:bold;
	background:transparent;
	border:2px solid #333;
}
.productinfo .orderlist {
	width:100%;
	height:calc(100vh - 325px);
	overflow:auto;
}
.productinfo .orderlist .row {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:flex-start;
	padding:5px;
	border-bottom:1px dashed #ccc;
}
.productinfo .orderlist .row:last-child {
	border:none;
}
.productinfo .orderlist .row .name {
	width:100%;
}
.productinfo .orderlist .row .quantity {
	width:50px;
	font-size:110%;;
}
.productinfo .orderlist .row .quantitytext {
	white-space:nowrap;
}
.productinfo .orderlist .row .measure {
	color:#999;
	font-size:90%;
}
.productinfo .orderlist .row .price {
	width:140px;
	text-align:right;
}
.productinfo .orderlist .row .delete {
	width:60px;
	text-align:right;
}
.productinfo .summary {
	padding:20px 10px 10px;
	background-color:#f2f2f2;
	background-image:linear-gradient(135deg, #fff 5px, transparent 5px), linear-gradient(225deg, #fff 5px, transparent 5px);
	background-size:10px 10px;
	background-repeat:repeat-x;
}
.productinfo .summary div.row {
	position:relative;
	display:flex;
	justify-content:space-between;
	flex-direction:row;
	flex-wrap:wrap;
}
.productinfo .summary div.row:before {
	content:'';
	position:absolute;
	bottom:5px;
	width:100%;
	height:0;
	line-height:0;
	border-bottom:2px dotted #ccc;
}
.productinfo .summary div.row span {
	background:#f2f2f2;
	padding-right:3px;
	z-index:1;
}
.productinfo .summary div.row span:last-child {
	background:#f2f2f2;
	padding-left:3px;
	z-index:1;
}
.productinfo .summary div.hint {
	width:100%;
	font-size:90%;
	color:#999;
	text-overflow:ellipsis;
}
.productinfo .cell {
	margin:0 10px 10px 0;
}
.ordercallcontainer .orderbody .clientcontainer .block {
	display:flex;
	flex-direction:column;
	padding:20px;
	padding-bottom:10px;
	margin-bottom:20px;
	background:#f2f2f2;
}
.ordercallcontainer .orderbody .clientcontainer .cell {
	display:flex;
	flex-direction:row;
	align-items:flex-end;	
}
.ordercallcontainer .orderbody .clientcontainer .cell:last-child {
	margin-right:0;
}
.ordercallcontainer .orderbody .clientcontainer .block input {
	width:100%;
}
.ordercallcontainer .orderbody .clientcontainer {
	overflow:hidden;
}
.ordercallcontainer .orderbody .clientcontainer .phoneblock {
	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-direction:row;
}
.ordercallcontainer .orderbody .clientcontainer .phoneblock .button {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	white-space:nowrap;
	padding:10px 15px;
	text-transform:uppercase;
	background:#999;
	border:none;
	color:#fff;
	font-size:100%;
	font-weight:bold;
	cursor:pointer;
	transition:.4s;
	margin-left:60px;
	border-radius:3px;
}
.ordercallcontainer .orderbody .clientcontainer .phoneblock .button:hover {
	background:#690;
}
.ordercallcontainer .orderbody .clientcontainer .phoneblock .button img {
	width:24px;
	margin-right:10px;
}
.ordercallcontainer .orderbody .clientcontainer input {
	width:100%;
}
.ordercallcontainer .orderbody .addresscontainer {
	height:100%;
	overflow:hidden;
}
.ordercallcontainer .orderbody .addresscontainer .addressblock {
	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-direction:row;
}
.ordercallcontainer .orderbody .addresscontainer .addressblock .button {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	white-space:nowrap;
	padding:10px 15px;
	text-transform:uppercase;
	background:#999;
	border:none;
	color:#fff;
	font-size:100%;
	font-weight:bold;
	cursor:pointer;
	transition:.4s;
	margin-left:60px;
	border-radius:3px;
}
.ordercallcontainer .orderbody .addresscontainer .addressblock .button:hover {
	background:#690;
}
.ordercallcontainer .orderbody .addresscontainer .addressblock .button img {
	width:24px;
	margin-right:10px;
}
.ordercallcontainer .orderbody .addresscontainer input {
	width:100%;
}
.ordercallcontainer .orderbody .addresscontainer .inputs {
	display:flex;
	flex-direction:row;
	align-items:flex-start;
	margin-top:10px;
}
.ordercallcontainer .orderbody .addresscontainer .inputs .row {
	width:25%;
	margin:5px 20px 0 0;
}
.ordercallcontainer .orderbody .addresscontainer .inputs .row:last-child {
	margin-right:0;
}
.ordercallcontainer .orderbody .addresscontainer .inputs input {
	display:block;
	width:100%;
}
.ordercallcontainer .orderbody .addresscontainer .inputs .row label {
	color:#999;
	font-size:90%;
}
.ordercallcontainer .orderbody .addresscontainer .inputs input[type=checkbox] {
	width:auto;
}
.ordercallcontainer .orderbody .addresscontainer .inputswide {
	margin-top:50px;
}
.ordercallcontainer .orderbody .addresscontainer .inputswide .row {
	width:240px;
}
.ordercallcontainer .orderbody .addresscontainer .inputswide.inputswidecomment input {
	width:640px;
}
.ordercallcontainer .orderbody .addresseslist {
	height:calc(100vh - 305px);
	margin-top:10px;
	overflow:auto;
}
.ordercallcontainer .orderbody .addresseslist .container {
	display:flex;
	justify-content:space-between;
	align-items:center;
	padding:10px;
	border-bottom:1px solid #eee;
}
.ordercallcontainer .orderbody .addresseslist .container b {
	font-size:130%;
}
.ordercallcontainer .orderbody .addresseslist .container span {
	display:block;
	color:#777;
}
.ordercallcontainer .orderbody .addresseslist .container .button {
	padding:7px 20px;
	background:#333;
	color:#fff;
	border:none;
	border-radius:3px;
	text-transform:uppercase;
	text-align:center;
}
.ordercallcontainer .orderbody .addresseslist .container .button:hover {
	opacity:.8;
	background:#690;
	cursor:pointer;
}
.ordercallcontainer .orderbody .orderadditionals .block {
	display:flex;
	flex-direction:row;
	padding:20px;
	padding-bottom:10px;
	margin-bottom:20px;
	background:#f2f2f2;
}
.ordercallcontainer .orderbody .orderadditionals .discountsblock .cell {
	display:flex;
	flex-direction:row;
	align-items:flex-end;
}
.ordercallcontainer .orderbody .orderadditionals .discountsblock .cell:last-child {
	margin-right:0;
}
.ordercallcontainer .orderbody .orderadditionals .block input {
	width:100%;
}
.ordercallcontainer .orderbody .orderadditionals .commentblock .cell {
	margin-right:0;
}
.ordercallcontainer .orderbody .orderadditionals .commentblock input {
	width:100%;
}
.ordercallcontainer .orderbody .orderadditionals .blockdate {
	margin-bottom:0;
}
.ordercallcontainer .orderbody .orderadditionals .blockdate div {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.ordercallcontainer .orderbody .orderadditionals .blockdate div img {
	margin-left:20px;
	cursor:pointer;
}
.formcontainer {
	overflow:hidden;
}
.formcontainer .cell {
	margin:0 20px 20px 0;
}
.formcontainer .cell {
	white-space:nowrap;
}
.formcontainer .cell label span {
	display:block;
	margin:0 0 5px 0;
	color:#777;
}
.formcontainer .cell label div {
	white-space:nowrap;
}
.formcontainer input {
	display:block;
	width:240px;
	font-size:140%;
	padding:10px 15px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.formcontainer input[type=checkbox] {
	width:20px !important;
	height:20px !important;
	margin-top:15px;
}
.formcontainer input:focus {
    background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}
.formcontainer .formcontainerinputs {
	display:flex;
	align-items:center;
	flex-direction:row;
	margin-top:10px;
}
.formcontainer .formcontainerinputs .cell {
	width:20%;
	margin:0 10px 5px 0;
}
.formcontainer .formcontainerinputs .cellsmall {
	width:auto;
}
.formcontainer .formcontainerinputs .cell span {
	font-size:80%;
	color:#666;
}
.formcontainer .formcontainerinputs .cell div {
	display:flex;
	align-items:center;
	flex-direction:row;
}
.formcontainer .formcontainerinputs .cell img {
	margin:0 20px 0 10px;
}
.formcontainer .formcontainerinputs input, .formcontainer .formcontainerinputs select {
	display:block;
	width:100%;
	font-size:100%;
	padding:5px 10px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.formcontainer .formcontainerinputs select {
	margin-right:15px;
	padding:6px 10px;
}
.formcontainer .formcontainerinputs input:focus, .formcontainer .formcontainerinputs select:focus {
    background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}
.formcontainer .formcontainerinputs input[type=checkbox] {
	width:auto;
	margin:6px 0;
}
.formcontainer .searchblock {
	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-direction:row;
}
.formcontainer .searchblock .button {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	white-space:nowrap;
	padding:10px 15px;
	text-transform:uppercase;
	background:#999;
	border:none;
	color:#fff;
	font-size:100%;
	font-weight:bold;
	cursor:pointer;
	transition:.4s;
	margin-left:15px;
	border-radius:3px;
}
.formcontainer .searchblock .button:hover {
	background:#690;
}
.formcontainer .searchblock .button img {
	width:24px;
	margin-right:10px;
}
.busyuser {
	padding:0 20px;
	height:70px;
	display:flex;
	align-items:center;
	font-size:120%;
	background:#ddd;
}
.controlpanel {
	padding:10px;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	background:#ddd;
}
.controlpanel div {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
}
.controlpanel .button {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	white-space:nowrap;
	padding:10px 20px;
	text-transform:uppercase;
	background:#690;
	font-size:120%;
	color:#fff;
	border:none;
	cursor:pointer;
	transition:.4s;
}
.controlpanel .button:hover {
	background:#333;
}
.controlpanel .button img {
	width:28px;
	margin-right:10px;
	filter:invert(1);
}
.controlpanel .button img.right {
	margin:0 0 0 10px;
}
.controlpanel .button.secondary {
	font-size:90%;
	background:#e0e0e0;
	border:2px solid #777;
	color:#333;
	padding:12px 18px 12px 12px;
	margin-left:10px;
}
.controlpanel .secondary:first-child {
	margin-left:auto;
}
.controlpanel .secondary img {
	margin:0 5px 0 0;
	filter:none;
	width:20px;
}
.controlpanel .button.cancel {
	background:rgba(204,0,0,.2);
	border-color:#c00;
}
.controlpanel .button.disabled {
	background:#aaa;
	opacity:.5;
	cursor:default;
}
.modalcontainer .header {
	padding:25px 20px 10px 20px;
	border-radius:5px 5px 0 0;
	background:#f2f2f2;
}
.modalcontainer .header h4 {
	margin-bottom:10px;
}
.promptcontainer {
	margin:20px 20px;
}
.promptcontainer div {
	display:flex;
	align-items:center;
	flex-direction:row;
	margin-top:30px;
}
.promptcontainer .button {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	white-space:nowrap;
	padding:10px 30px;
	text-transform:uppercase;
	background:#690;
	font-size:120%;
	color:#fff;
	border:none;
	cursor:pointer;
	transition:.4s;
}
.promptcontainer .button:hover {
	background:#333;
}
.promptcontainer .button.secondary {
	background:#999;
	font-weight:normal;
	margin-left:20px;
}
.promptcontainer .button.hold {
	background:#eee;
	color:#555;
	margin-left:auto;
}
.areaselectcontainer {
	margin-top:10px;
}
.areaselectcontainer p {
	margin:20px;
}
.areaselectcontainer .row {
	padding:10px 20px;
	border-bottom:1px solid #eee;
	display:flex;
	justify-content:space-between;
	align-items:center;
}
.areaselectcontainer .rowfirst {
	border-top:1px solid #eee;
}
.areaselectcontainer .row span {
	display:block;
	font-size:80%;
	color:#999;
}
.areaselectcontainer .button {
	padding:10px 0;
	width:180px;
	background:#333;
	color:#fff;
	border:none;
	border-radius:3px;
	text-transform:uppercase;
	text-align:center;
}
.areaselectcontainer .button.selected {
	background:#690;
}
.areaselectcontainer .button:hover {
	opacity:.8;
	cursor:pointer;
}
.ordersearchcontainer {
	width:100%;
	margin-top:5px;
	overflow:hidden;
	background:#fff;
	border-radius:3px 3px 0 0;
}
.ordersearchcontainer .header {
	position:relative;
	padding:20px 10px;
	background:#fff;
	border-bottom:1px solid #ccc;
}
.ordersearchcontainer .orderbody {
	padding:20px 10px;
}
.ordersearchcontainer .orderbody input {
	width:100%;
}
.ordersearchcontainer .orderbody .orderscontainer {
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
	align-content:flex-start;
	width:100%;
	height:calc(100vh - 290px);
	margin:10px auto 0;
	overflow:auto;
	padding-bottom:20px;
}
.noscrollbar::-webkit-scrollbar {
	width: 0;
}
.ordersearchcontainer .orderbody .orderscontainer .orderitem {
	width:315px;
	max-height:220px;
	background:#f8f8f8;
	padding:20px;
	margin:5px;
	border-radius:3px;
	box-shadow:0 1px 2px rgba(37,48,62,.2);
	cursor:pointer;
	transition:.4s;;
	overflow:hidden;
}
.ordersearchcontainer .orderbody .orderscontainer .orderitem .orderiteminner {
	width:100%;
	max-height:160px;
	overflow:hidden;
}
.ordersearchcontainer .orderbody .orderscontainer .orderitem:hover {
	opacity:.9;
}
.ordersearchcontainer .orderbody .orderscontainer .orderitem .block {
	display:flex;
	justify-content:space-between;
	align-items:flex-start;
	margin-bottom:10px;
}
.ordersearchcontainer .orderbody .orderscontainer .orderitem .block:first-child {
	align-items:center;
}
.ordersearchcontainer .orderbody .orderscontainer .orderitem .number {
	font-size:110%;
	margin-bottom:5px;
	font-weight:bold;
}
.ordersearchcontainer .orderbody .orderscontainer .orderitem .products {
	margin:10px 0 10px;
	font-size:90%;
}
.ordersearchcontainer .orderbody .orderscontainer .orderitem .status {
	font-size:80%;
	padding:0 6px;
	border-radius:3px;
	color:#555;
	background:#eee;
}
.ordersearchcontainer .orderbody .orderscontainer .orderitem .amount {
	white-space:nowrap;
}

.ordershowcontainer {
	position:relative;
	width:100%;
	height:calc(95vh - 290px);
	margin-top:5px;
	overflow-y:scroll;
	background:#fff;
	border-radius:3px 3px 0 0;
}
.ordershowcontainer .header {
	position:relative;
	padding:20px 10px;
	margin-bottom: 10px;
	background:#fff;
	border-bottom:1px solid #ccc;
}
.ordershowcontainer .orderbody {
	padding:0 10px;
}
.ordershowcontainer .orderbody input {
	width:100%;
}
.ordershowcontainer .orderbody .orderscontainer {
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
	align-content:flex-start;
	width:100%;
	height:calc(85vh - 290px);
	margin:10px auto 0;
	overflow:auto;
	padding-bottom:20px;
}
.ordershowcontainer .orderbody .orderscontainer .orderitem {
	width:300px;
	max-height:220px;
	background:#f8f8f8;
	padding:20px;
	margin:5px;
	border-radius:3px;
	box-shadow:0 1px 2px rgba(37,48,62,.2);
	cursor:pointer;
	transition:.4s;
	overflow:hidden;
}
.ordershowcontainer .orderbody .orderscontainer .orderitem .orderiteminner {
	width:100%;
	max-height:160px;
	overflow:hidden;
}
.ordershowcontainer .orderbody .orderscontainer .orderitem:hover {
	opacity:.9;
}
.ordershowcontainer .orderbody .orderscontainer .orderitem .block {
	display:flex;
	justify-content:space-between;
	align-items:flex-start;
	margin-bottom:10px;
}
.ordershowcontainer .orderbody .orderscontainer .orderitem .block:first-child {
	align-items:center;
}
.ordershowcontainer .orderbody .orderscontainer .orderitem .number {
	font-size:110%;
	margin-bottom:5px;
	font-weight:bold;
}
.ordershowcontainer .orderbody .orderscontainer .orderitem .products {
	margin:10px 0 10px;
	font-size:90%;
}
.ordershowcontainer .orderbody .orderscontainer .orderitem .status {
	font-size:80%;
	padding:0 6px;
	border-radius:3px;
	color:#555;
	background:#eee;
}
.ordershowcontainer .orderbody .orderscontainer .orderitem .amount {
	white-space:nowrap;
}

.promptcontainer .cancelinput {
	flex-direction:column;
	align-items:flex-start;
	justify-content:flex-start;
}
.promptcontainer .cancelinput p {
	margin-bottom:20px;
	font-size:110%;
}
.promptcontainer .cancelinput input {
	display:block;
	width:100%;
	font-size:140%;
	padding:10px 15px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.promptcontainer .cancelinput input:focus {
    background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}
.mapcontainer {
	display:flex;
	flex-direction:column;
	width:100%;
}
.mapcontainer .map {
	position:relative;
	width:100%;
	height:590px;
	background:#eee url('../../../Images/loading.svg') 50% 50% no-repeat;
}
.orderaddress {
	display:flex;
	justify-content:space-between;
	align-items:center;
	font-size:120%;
	padding:10px 0 0 20px;
}
.orderaddress img.update {
	width:30px;
	height:30px;
	margin-right:20px;
	cursor:pointer;
}
.mainfull {
	width:1024px;
	margin:10px auto 0;
	padding-bottom:20px;
	overflow:hidden;
	background:#fff;
}
.mainfull .header {
	position:relative;
	padding:20px 10px;
	background:#fff;
	border-bottom:1px solid #ccc;
}
.mainfull .inner {
	height:calc(100vh - 140px);
	padding:0 20px 20px;
	padding-bottom:0;
	overflow:auto;
}
.mainfull .inner .row {
	display:flex;
	align-items:flex-start;
	flex-direction:row;
	padding:20px 0;
	border-bottom:1px solid #ccc;
}
.mainfull .inner .row:last-child {
	border:none;
}
.mainfull .inner .row .name {
	width:280px;
}
.mainfull .inner .row .name b {
	display:block;
	font-size:120%;
}
.mainfull .inner .row .numdate {
	width:240px;
}
.mainfull .inner .row .numdate span {
	display:block;
	font-size:120%;
}
.mainfull .inner .row .status {
	width:220px;
}
.mainfull .inner .row .statusWait {
	display:inline-block;
	background:#fffcc0;
}
.mainfull .inner .row .couriername {
	width:320px;
}
.mainfull .inner .row .couriername b {
	display:block;
	font-size:120%;
}
.mainfull .inner .row .infobutton {
	display:flex;
	flex-direction:row;
	align-items:center;
	padding:10px 15px;
	text-transform:uppercase;
	background:#333;
	border:none;
	color:#fff;
	font-size:100%;
	font-weight:bold;
	cursor:pointer;
	transition:.4s;
	margin-left:auto;
	border-radius:3px;
}
.mainfull .inner .row .infobutton:hover {
	background:#690;
}
.mainfull .inner .row .orderslist {
	width:680px;
	display:flex;
	align-items:flex-start;
	flex-direction:row;
	flex-wrap:wrap;
}
.mainfull .inner .row .orderslist .orderblock {
	width:160px;
	font-size:90%;
	height:70px;
	padding:10px;
	margin:0 5px 10px 5px;
	background:#f8f8f8;
	border-radius:3px;
	box-shadow:0 1px 2px rgba(37,48,62,.2);
	cursor:pointer;
	transition:.4s;
	overflow:hidden;
}
.mainfull .inner .row .orderslist .orderblock:active {
	background:#ccc;
}
.mainfull .inner .row .orderslist .orderblock span {
	display:block;
}
.mainfull .map {
	position:relative;
	width:100%;
	height:calc(100vh - 80px);
	background:#eee url('../../../Images/loading.svg') 50% 50% no-repeat;
}
.modalcontainermodal {
	overflow:hidden;
	border-radius:5px;
}
.modalcontainermodal .header {
	padding:25px 20px 10px 20px;
	border-radius:5px 5px 0 0;
	background:#f2f2f2;
}
.modalcontainermodal .header h4 {
	margin-bottom:10px;
}
.modalcontainermodal .header h4 .status {
	position:relative;
	top:-2px;
	font-size:80%;
	font-weight:normal;
	margin-left:15px;
	padding:2px 8px 3px;
	color:#fff;
	border-radius:3px;
}
.modalcontainermodal .header h4 .status.statuswork {
	background:#999;
}
.modalcontainermodal .header h4 .status.statusdone {
	background:#690;
}
.modalcontainermodal .header .mapbutton {
	position:absolute;
	top:20px;
	left:50%;
	display:flex;
	justify-content:space-between;
	align-items:center;
	padding:5px 25px 5px 15px;
	background:#fff;
	border-radius:5px;
	cursor:pointer;
	font-size:120%;
}
.modalcontainermodal .header .mapbutton img {
	width:32px;
	height:32px;
	margin-right:10px;
}
.modalcontainermodal .productslist {
	height:580px;
	padding:10px 20px 0;
	overflow:auto;
}
.modalcontainermodal .productslist .item {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	padding:10px 5px;
	border-bottom:1px solid #ccc;
}
.promptcontainercash {
	display:block;
}
.promptcontainercash div {
	display:block;
	margin:0;
}
.promptcontainercash .title {
	margin-bottom:10px;
	font-size:120%;
	font-weight:600;
}
.promptcontainercash .money {
	margin-bottom:10px;
	font-size:200%;
	font-weight:700;
}
.promptcontainercash .moneymore {
	color:#278245;
}
.promptcontainercash .moneyless {
	color:#c00;
}
.promptcontainercash input {
	font-weight:700;
	font-size:200%;
	padding:10px 15px;
	letter-spacing:unset;
}
.promptcontainercash ul {
	display:flex;
	flex-direction:row;
	align-items:center;
	margin-top:15px;
	margin-bottom:20px;
}
.promptcontainercash ul li {
	padding:5px 10px;
	margin-right:10px;
	border-radius:5px;
	background:#eee;
	cursor:pointer;
}
.promptcontainercash ul li.selected {
	background:#555;
	color:#fff;
}
.promptcontainercash .control {
	margin-top:50px;
}
.ordershowcontainer .clientinfo .cell {
	margin: 0 0 10px 0;
}

.statusOnline, .statusOffline {
	display: block;
	font-size: 12px;
	width: fit-content;
	padding: 2px 4px;
	border-radius: 5px;
	font-weight: bold;
}
.statusOnline {
	background-color: #27ae60;
	color: white;
}
.statusOffline {
	background-color: #bdc3c7;
	color: #57606f;
}

.promoSection {
	display: flex;
	flex-direction: column;
	padding: 10px;
	margin-bottom: 10px;
	cursor: auto;
  }

  .promoButtons {
	width: 100%;
  }
  
  .promoSection > label {
	font-weight: bold;
	margin-bottom: 10px;
  } 
  
  .promoSection > div > div > label {
	font-weight: bold;
	margin-top: 20px;
  }
  
  .promoSection ul {
	margin: 0;
	padding: 0;
	margin-bottom: 20px;
  }
  
  .promoSection ul::after {
	content: '';
	border-bottom: 1px solid #ccc;
	width: 100%;
  }
  
  .promoSection li {
	margin: 5px 0;
	width: 100%;
	display: block;
  }
  
  .promoSection input[type="text"],
  .promoSection input[type="number"],
  .promoSection select {
	padding: 5px;
	border: 1px solid #ccc;
	border-radius: 3px;
	margin-right: 10px;
  }
  
  .promoSection button:not(.handleAddPromo) {
	padding: 5px 10px;
	background-color: #333;
	color: #fff;
	border: none;
	border-radius: 3px;
	cursor: pointer;
	margin-right: 5px;
  }
  .promoSection button:hover {
	opacity: 0.9;
  }
  .promoSection .notactive {
	opacity: 0.5;
	cursor: auto !important;
  }
  .promoSection .notactive:hover {
	opacity: 0.5;
	cursor: auto !important;
  }
  
  .promoSection .sliderContainer {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
  }
  
  .promoSection .slider {
	flex-grow: 1;
	margin-right: 10px;
  }
  
  .promoSection .discountValue {
	font-weight: bold;
	color: rgb(63, 63, 63);
  }

  .discountCode {
	font-size: 16px;
	font-weight: bold;
	color: #2c3e50;
  }

  .promoborder {
	content: '';
	width: 100%;
	border-bottom: 1px solid #dfe4ea;
	display: block;
	margin-top: 10px;
  }

  .promoAdd::before {
	content: '';
	width: 100%;
	border-top: 1px solid #dfe4ea;
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
  }
  .promoAdd > label {
	margin-top: 10px;
	width: 100%;
	display: block;
  }
  .discountInput {
	margin-bottom: 15px;
  }
  .promoDelete {
		display: inline-block;
  }
  .promoAdd {
	  display: block;
	  margin-top: 10px;
  }
  .handleAddPromo {
	padding: 5px 10px;
	background-color: #2980b9;
	color: #fff;
	border: none;
	border-radius: 3px;
	cursor: pointer;
	margin-right: 5px;
  }
  
.clientinfo .cell button.button_bonuces {
	background-color: rgb(78, 116, 1);
}
.clientinfo .cell button.button_bonuces_active {
	background-color: #690;
}
.useBonuces {
	text-decoration: line-through;
}
.messagescontainer .row {
	padding:10px 0;
	border-bottom:1px solid #ccc;
	cursor:pointer;
}
.messagescontainer .row .messageinfo {
	display:flex;
	align-items:flex-start;
	flex-direction:row;
}
.messagescontainer .row .date {
	width:150px;
}
.messagescontainer .row .name {
	width:180px !important;
}
.messagescontainer .row .message {
	width:auto;
}
.dialogcontainer .row {
	padding:10px 0;
	border-bottom:1px solid #ccc;
	cursor:pointer;
}
.dialogcontainer .inner {
	height:calc(100vh - 300px);
	padding:0 20px 20px;
	padding-bottom:0;
	overflow:auto;
}
.dialogcontainer .messagecontrol {
	width:auto;
	margin:0 0 0 10px;
	padding:20px;
	background:#f1f1f1;
}
.dialogcontainer .messagecontrol textarea {
	display:block;
	width:100%;
	height:80px;
	padding:10px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
}
.dialogcontainer .messagecontrol textarea:focus {
    background-color: #fff;
    outline: 0 !important;
}
.dialogcontainer .messagecontrol .buttons {
	margin-top:10px;
}
.dialogcontainer .messagecontrol .buttons button {
	font-size:16px;
	padding:8px 12px;
	color:#fff;
	border-radius:3px;
	background:#555;
	cursor:pointer;
}