.container {
	width:100%;
	display:flex;
	justify-content:space-between;
	flex-direction:row;
	overflow:hidden;
	flex-wrap:wrap;
}
.loading {
	margin:40px 0 0 30px;
}
.main {
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
	align-content:flex-start;
	justify-content:flex-start;
	width:1024px;
	margin:10px auto 0;
	padding-bottom:20px;
	overflow:auto;
}
.main .block {
	width:50%;
	height:100%;
	padding:20px;
	overflow:hidden;
}
.main .block:first-child {
	border-right:4px solid #ccc;
}
.main .block .inner {
	display:flex;
	justify-content:flex-start;
	align-items:flex-start;
	flex-direction:row;
	flex-wrap:wrap;
}
.main .block h3 {
	margin:0 0 10px 5px;
}
.main .block .inner .item {
	position:relative;
	width:calc(50% - 10px);
	max-height:200px;
	padding:20px;
	padding:20px;
	margin:5px;
	font-size:320%;
	font-weight:bold;
	border-radius:3px;
	box-shadow:0 1px 2px rgba(37,48,62,.2);
	cursor:pointer;
	transition:.4s;
	overflow:hidden;
}
.main .block .inner .item.prepare {
	background:#ccc;
}
.main .block .inner .item.done {
	background:rgba(0,204,0,.2);
}
.main .block .inner .item span {
	display:block;
	position:absolute;
	bottom:10px;
	font-size:35%;
}
.modalcontainer .header {
	padding:25px 20px 10px 20px;
	border-radius:5px 5px 0 0;
	background:#f2f2f2;
}
.modalcontainer .header h4 {
	margin-bottom:10px;
}
.areaactive {
	position:absolute;
	top:20px;
	left:45%;
	display:flex;
	align-items:center;
}
.areaactive button {
	display:flex;
	align-items:center;
	margin-right:20px;
	font-size:100%;
	color:#333;
	border:none;
	padding:8px 14px;
	cursor:pointer;
	transition:.4s;
	border-radius:5px;
}
.areaactive button img {
	width:18px;
	margin-right:5px;
}
.areaselectcontainer {
	margin-top:10px;
}
.areaselectcontainer p {
	margin:20px;
}
.areaselectcontainer .row {
	padding:10px 20px;
	border-bottom:1px solid #eee;
	display:flex;
	justify-content:space-between;
	align-items:center;
}
.areaselectcontainer .row span {
	display:block;
	font-size:80%;
	color:#999;
}
.areaselectcontainer .button {
	padding:10px 0;
	width:180px;
	background:#333;
	color:#fff;
	border:none;
	border-radius:3px;
	text-transform:uppercase;
	text-align:center;
}
.areaselectcontainer .button.selected {
	background:#690;
}
.areaselectcontainer .button:hover {
	opacity:.8;
	cursor:pointer;
}