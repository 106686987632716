.loadFile {
    margin-top: 20px;
    background-color: #FFF;
    border-radius: 8px;
    padding: 10px 20px;
    box-shadow: 1px 1px 3px rgba(0,0,0,.1);
}

.loadFile input {
    border: none;
}

.loadFile button {
    border: none;
    background-color: #EEE;
    border-radius: 8px;
    padding: 5px 10px;
}

.loadFile button:hover {
    opacity: .8;
    cursor: pointer;
}

.table > table {
    width: 100%;
    border-collapse: collapse;
}

.table {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #FFF;
    border-radius: 8px;
    box-shadow: 1px 1px 3px rgba(0,0,0,.1);
}
  
.table table tr th, .table table tr td {
    border: 1px solid #EEE;
    padding: 8px;
    text-align: center;
}
  
.table table th {
    background-color: #f2f2f2;
}
  
.table > table > button {
    padding: 8px;
    margin-right: 5px;
    cursor: pointer;
    border: none;
    background-color: #4caf50;
    color: white;
}
  
.table > table > button:hover {
    background-color: #45a049;
}