.container h4 {
	display:flex;
	align-items:center;
	justify-content:space-between;
}
.container h4 span a {
	margin-right:10px;
}
.container h4 a.add {
	display:flex;
	align-items:center;
	border:2px solid #000;
	font-size:15px;
	font-weight:bold;
	color:#000;
	text-decoration:none;
	padding:2px 12px 2px 6px;
	border-radius:4px;
}
.container h4 a.add:hover {
	border-color:#999;
	background:rgba(255,255,255,.8);
	color:#00000080;
}
.container h4 a.add:before {
	content:'';
	display:block;
	width:24px;
	height:24px;
	margin-right:4px;
	background:url('../../Images/icons/add.svg') 0 0 no-repeat;
}
.container h4 a.add:hover:before {
	opacity:.5;
}
.formcontainer {
	margin-top:20px;
    padding:20px;
    border-radius:4px;
    box-shadow:0 2px 2px rgba(204,197,185,.5);
    background:#fff;
    margin-bottom:20px;
    position:relative;
    z-index:1;
    zoom:1;
    overflow:hidden;
}
.formcontainer form {
	max-width:900px;
}
.formcontainer .cell label span {
	display:block;
	margin:0 0 5px 0;
	color:#777;
}
.formcontainer .cell .marker {
	width:22px;
	margin-left:10px;
	cursor:pointer;
}
.formcontainer h4.title {
	margin-bottom:20px;
}
.oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.addressblock {
	display:flex;
	flex-direction:row;
	align-items:flex-start;
}
.ordercontainer input, .orderviewcontainer input {
	display:block;
	width:215px;
	padding:5px;
	margin-right:20px;;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.ordercontainer input:focus, .orderviewcontainer input:focus {
    background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}
.clientorderheader {
	display:flex;
	flex-direction:row;
	align-items:flex-start;
	border-bottom:1px solid #ddd;
	margin-bottom:10px;
}
.clientorderheader:last-child {border:none;}
.clientorderheader .cell {
	margin:0 40px 10px 0;
}
.clientorderheader .cell label span {
	display:block;
	margin:0 0 2px 0;
	color:#777;
}
.clientorderheader select {
	display:block;
	width:215px;
	padding:5px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.clientorderheader select:focus {
    background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}
.productscontainer {
	background:#f8f8f8;
	padding:10px;
	margin:10px 0;
	overflow:auto;
	height:200px;
}
.productscontainer ul li {
	display:flex;
	align-items:center;
	justify-content:space-between;
	padding:5px;
	margin-bottom:5px;
	border-bottom:1px solid #e5e5e5;
}
.productscontainer ul li img {
	width:16px;
	opacity:.7;
}
.productscontainer ul li img:hover {
	cursor:pointer;
	opacity:1;
}
.summary, .summaryview {
	display:flex;
	width:100%;
	flex-direction:row;
	justify-content:space-between;
	margin-top:20px;
	padding:10px;
	background:#f8f8f8;
}
.summaryview {
	margin:0 0 10px 0;
}
.summary .summaryblock, .summaryview .summaryblock {
	width:30%;
}
.summary .summaryblock .hint, .summaryview .summaryblock .hint {
	display:block;
	font-size:80%;
	color:#999;
}
.map {
	position:relative;
	width:100%;
	height:100%;
	border-radius:5px;
	background:#eee url('../../Images/loading.svg') 50% 50% no-repeat;
}
.centerMarker {
    width:40px;
    height:40px;
    background:url('../../Images/icons/marker.svg') 0 0 no-repeat;
    position:absolute;
    left:50%;
    margin-left:-20px;
    top:50%;
    margin-top:-20px;
}
.statuseslist {}
.statuseslist li {
	display:flex;
	align-items:center;
	flex-direction:row;
}
.statuseslist li div {
	margin-right:30px;
}
.orderviewcontainer select {
	display:block;
	width:215px;
	padding:5px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.orderviewcontainer select:focus {
    background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}