.container {
    width:100%;
    display:flex;
    justify-content:space-between;
    flex-direction:row;
    overflow:hidden;
    flex-wrap:wrap;
}
.loading {
    margin:40px 0 0 30px;
}
.main {
    width:1024px;
    margin:10px auto 0;
    padding-bottom:20px;
}
.main .header {
    padding:10px;
    margin:0 5px 10px;
    border-bottom:2px solid #ccc;
    font-size:120%;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
}
.main .header button {
	border:2px solid #555;
	padding:8px 10px;
	cursor:pointer;
	transition:.4s;
	border-radius:5px;
	font-size:70%;
	text-transform:uppercase;
	margin-left:20px;
	font-weight:bold;
}
.main .header button.hide {
	border-color:#999;
}
.main .viewcontols {
	padding:10px;
	margin:0 5px 10px;
	border-bottom:2px solid #ccc;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
}
.main .viewcontols .tabs {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.main .viewcontols .tabs .tab {
	padding:6px 10px;
	cursor:pointer;
	transition:.4s;
	border-radius:5px;
	margin-right:10px;
	text-transform:uppercase;
	font-weight:bold;
	background:#fff;
}
.main .viewcontols .tabs .tabmargin {
	margin-left:20px;
}
.main .viewcontols .tabs .tabselected {
	background:#333;
	color:#fff;
}
.main .viewcontols .tabs .tabselectedcurrent {
	background:#690;
	color:#fff;
}
.main .viewcontols .tabs .tabgroup {
	padding:6px 10px;
	cursor:pointer;
	transition:.4s;
	border-radius:5px;
	margin-left:10px;
	text-transform:uppercase;
	font-weight:bold;
	background:#fff;
}
.main .viewcontols .tabs .tabgroupselected {
	background:#ccc;
}
.main .innercontainer {
	width:100%;
	height:calc(100vh - 210px);
	overflow:auto;
}
.main .innercontainersection {
	height:calc(100vh - 265px);
}
.main .orderslistinner {
	width:100%;
	height:100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:flex-start;
    align-content:flex-start;
}
.main .orderslistinner .orderslist {
	width:700px;
	height:100%;
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
	justify-content:flex-start;
	/* align-items:flex-start; */
	align-items:stretch;
	align-content:flex-start;
}
.main .orderslistinner .orderslistfull {
	width:100%;
}
.main .orderslistinner .statisticinfo {
	width:320px;
	height:calc(100% - 10px);
	padding:5px;
	background:#fff;
	margin-left:auto;
}
.main .orderslistinner .statisticinfo .title {
	width:100%;
	text-transform:uppercase;
	font-weight:600;
	color:#fff;
	margin-bottom:10px;
	padding:10px;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	border-radius:3px;
	box-shadow:0 1px 2px rgba(37,48,62,.2);
	background:#6c42a6;;
}
.main .orderslistinner .statisticinfo .titleall {
	background:#843014
}
.main .orderslistinner .statisticinfo .titledebt {
    background-color: #c00;
}
.main .orderslistinner .statisticinfo .block {
	padding:10px;
}
.main .orderslistinner .statisticinfo .block .empty {
	margin:0;
	margin-bottom:20px;
	font-weight:bold;
}
.main .orderslistinner .statisticinfo .block ul li {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	margin-bottom:5px;
	padding-bottom:5px;
	border-bottom:1px solid #ccc;
	font-weight:bold;
}
.main .orderslistinner .statisticinfo .block ul li span {
	font-weight:normal;
}
.main .item {
    width:325px;
    min-height:200px;
    padding:20px;
    background:#f8f8f8;
    padding:20px;
    margin:5px;
    border-radius:3px;
    box-shadow:0 1px 2px rgba(37,48,62,.2);
    cursor:pointer;
    transition:.4s;
    overflow:hidden;
}
.main .inneritemlite {
    min-height:initial;
    max-height:200px;
}
.main .item .inneritem {
    width:100%;
    max-height:160px;
    overflow:hidden;
}
.main .item.alarm {
    background:rgba(204,0,0,.2);
}
.main .item.paid {
    background:rgba(0,204,0,.2);
}
.main .item:hover {
    opacity:.9;
}
.main .item .block {
    display:flex;
    justify-content:space-between;
    align-items:flex-start;
    margin-bottom:10px;
}
.main .item .block:first-child {
    align-items:center;
}
.main .item .number {
    font-size:110%;
    margin-bottom:5px;
    font-weight:bold;
}
.main .item .delivery {
	font-size:80%;
	background:#ddd;
	padding:2px 6px;
	text-transform:lowercase;
	border-radius:4px;
}
.main .item .products {
    margin:10px 0 10px;
    font-size:90%;
}
.main .item .status {
    font-size:80%;
    padding:0 6px;
    border-radius:3px;
    color:#555;
    background:#eee;
}
.main .item .date {
	display:inline-block;
	font-weight:bold;
	font-size:80%;
	padding:2px 6px;
	border-radius:3px;
	color:#fff;
	background:#c00;
}
.main .empty {
	margin:10px 0 0 20px;
    font-weight:bold;
}
.main .orderslistinner .orderslist .item {
	min-height:auto;
	position:relative;
	cursor:default;
}
.main .orderslistinner .orderslist .item .name {
	font-size:130%;
	font-weight:600;
	line-height:1.1;
}
.main .orderslistinner .orderslist .item .measure {
	color:#666;
	margin-bottom:10px;
}
.main .orderslistinner .orderslist .item .quantity {
	position:absolute;
	right:5px;
	bottom:5px;
	display:inline-block;
	font-weight:600;
	font-size:120%;
	background:#333;
	color:#fff;
	border-radius:4px;
	padding:4px 12px;
}
.modalcontainer .header {
    padding:25px 20px 10px 20px;
    border-radius:5px 5px 0 0;
    background:#f2f2f2;
}
.modalcontainer .header h4 {
    margin-bottom:10px;
}
.modalcontainer .productslist {
    height:556px;
    padding:10px 20px 0;
    overflow:auto;
    border-radius:0 0 5px 5px;
}
.modalcontainer .productslistsection {
    height:501px;
}
.modalcontainer .productslist .item {
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    padding:10px 5px;
    border-bottom:1px solid #ccc;
}
.modalcontainer .productslist .item:last-child {
    border:none;
    margin-bottom:20px;
}
.modalcontainer .productslist .item .name {
    width:100%;
}
.modalcontainer .productslist .item .measure {
    color:#999;
    width:80px;
}
.modalcontainer .productslist .item .statusDone {
    opacity:.2;
}
.modalcontainer .productslist .item .status {
    width:140px;
    text-align:center;
    font-weight:bold;
}
.modalcontainer .productslist .item .status div {
    padding:7px;
}
.modalcontainer .productslist .item .status button {
    width:100%;
    background:#67ac5b;
    color:#fff;
    border:none;
    padding:10px;
    cursor:pointer;
    transition:.4s;
    border-radius:3px;
    text-transform:uppercase;
}
.modalcontainer .productslist .item .status button.ready {
	background:#333;
}
.modalcontainer .finish {
    display:flex;
    align-items:center;
    height:60px;
    font-weight:400;
    font-size:120%;
}
.modalcontainer .done img {
    width:20px;
    margin-right:10px;
    filter:invert(1);
}
.modalcontainer .done button {
    padding:10px 20px;
    background:#333;
    color:#fff;
    border:none;
    border-radius:3px;
    margin-left:10px;
    text-transform:uppercase;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
}
.modalcontainer .end {
    background:#ccc;
    height:5px;
    border-radius:0 0 5px 5px;
}
.areaactive {
    position:absolute;
    top:20px;
    left:45%;
    display:flex;
    align-items:center;
}
.areaactive button {
    display:flex;
    align-items:center;
    margin-right:20px;
    font-size:100%;
    color:#333;
    border:none;
    padding:8px 14px;
    transition:.4s;
    border-radius:5px;
}
.areaactive button img {
    width:18px;
    margin-right:5px;
}
.mainfull {
    width:1024px;
    margin:10px auto 0;
    padding-bottom:20px;
    overflow:hidden;
    background:#fff;
}
.mainfull .header {
    position:relative;
    padding:20px 10px;
    background:#fff;
    border-bottom:1px solid #ccc;
}
.mainfull .inner {
    height:calc(100vh - 200px);
    padding:20px;
    padding-bottom:0;
    overflow:auto;
}
.mainfull .inner .row {
    display:flex;
    align-items:flex-start;
    flex-direction:row;
    padding:20px 0;
    border-bottom:1px solid #ccc;
}
.mainfull .inner .row:last-child {
    border:none;
}
.mainfull .inner .row .name {
    width:280px;
}
.mainfull .inner .row .name b {
    display:block;
    font-size:120%;
}
.mainfull .inner .row .stat {
    display:flex;
    align-items:center;
    justify-content:flex-start;
    flex-direction:column;
    width:80px;
    padding:5px;
    text-align:center;
    font-size:90%;
    border-left:1px solid #ccc;
}
.mainfull .inner .row .statlast {
    margin-right:20px;
    border-right:1px solid #ccc;
}
.mainfull .inner .row .stat span {
    font-weight:bold;
}
.mainfull .inner .row .numdate {
    width:240px;
}
.mainfull .inner .row .numdate span {
    display:block;
    font-size:120%;
}
.mainfull .inner .row .status {
    width:220px;
}
.mainfull .inner .row .couriername {
    width:320px;
}
.mainfull .inner .row .couriername b {
    display:block;
    font-size:120%;
}
.mainfull .inner .row .infobutton {
    display:flex;
    flex-direction:row;
    align-items:center;
    padding:10px 15px;
    text-transform:uppercase;
    background:#333;
    border:none;
    color:#fff;
    font-size:100%;
    font-weight:bold;
    cursor:pointer;
    transition:.4s;
    margin-left:auto;
    border-radius:3px;
}
.mainfull .inner .row .infobutton:hover {
    background:#690;
}
.mainfull .inner .row .orderslist {
    width:520px;
    display:flex;
    align-items:flex-start;
    flex-direction:row;
    flex-wrap:wrap;
}
.mainfull .inner .row .orderslist .orderblock {
    width:160px;
    font-size:90%;
    height:70px;
    padding:10px;
    margin:0 5px 10px 5px;
    background:#f8f8f8;
    border-radius:3px;
    box-shadow:0 1px 2px rgba(37,48,62,.2);
    cursor:pointer;
    transition:.4s;
    overflow:hidden;
}
.mainfull .inner .row .orderslist .orderblock:active {
    background:#ccc;
}
.mainfull .inner .row .orderslist .orderblock span {
    display:block;
}
.mainfull .map {
    position:relative;
    width:100%;
    height:calc(100vh - 80px);
    background:#eee url('../../../Images/loading.svg') 50% 50% no-repeat;
}

.title {
	display:flex;
	flex-direction:row;
	align-items:flex-start;
	border-bottom:1px solid #ddd;
	margin-bottom:10px;
}
.titlenoborder {
	border-bottom:none;
	margin:0;
}
.title:last-child {
	border:none;
	margin-bottom:0;
}
.title .cell {
	margin:0 40px 10px 0;
}
.title .cell .phone {
	white-space:nowrap;
}
.title .cell label span {
	display:block;
	margin:0 0 2px 0;
	color:#777;
	white-space:nowrap;
}
.productslist {
	width:100%;
}
.productslist .item {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:flex-start;
	padding:5px;
	border-bottom:1px dashed #ccc;
}
.productslist .item:last-child {
	border:none;
}
.productslist .item .name {
	width:100%;
}
.productslist .item .measure {
	color:#999;
	font-size:90%;
}
.productslist .item .count {
	color:#999;
	width:100px;
	text-align:right;
}
.productslist .item .price {
	width:160px;
	text-align:right;
}
.summaryview {
	width:100%;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	padding:20px 10px 10px;
	margin:0 0 10px 0;
	background-color:#f2f2f2;
	background-image:linear-gradient(135deg, #fff 5px, transparent 5px), linear-gradient(225deg, #fff 5px, transparent 5px);
	background-size:10px 10px;
	background-repeat:repeat-x;
}
.summaryview .summaryblock {
	width:30%;
}