.container h4 {
	display:flex;
	align-items:center;
	justify-content:space-between;
}
.container h4 span a {
	margin-right:10px;
}
.container h4 a.add {
	display:flex;
	align-items:center;
	border:2px solid #000;
	font-size:15px;
	font-weight:bold;
	color:#000;
	text-decoration:none;
	padding:2px 12px 2px 6px;
	border-radius:4px;
}
.container h4 a.add:hover {
	border-color:#999;
	background:rgba(255,255,255,.8);
	color:#00000080;
}
.container h4 a.add:before {
	content:'';
	display:block;
	width:24px;
	height:24px;
	margin-right:4px;
	background:url('../../../Images/icons/add.svg') 0 0 no-repeat;
}
.container h4 a.add:hover:before {
	opacity:.5;
}
.formcontainer {
	margin-top:20px;
    padding:20px;
    border-radius:4px;
    box-shadow:0 2px 2px rgba(204,197,185,.5);
    background:#fff;
    margin-bottom:20px;
    position:relative;
    z-index:1;
    zoom:1;
    overflow:hidden;
}
.formcontainer form {
	max-width:900px;
}
.formcontainer .cell {
	margin:0 20px 20px 0;
}
.formcontainer .cell label span {
	display:block;
	margin:0 0 5px 0;
	color:#777;
}
.formcontainer input, .formcontainer select, .formcontainer textarea {
	display:block;
	width:215px;
	padding:10px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.formcontainer input[type=checkbox] {width:auto;}
.formcontainer input:focus, .formcontainer select:focus, .formcontainer textarea:focus {
    background-color: #fff;
    box-shadow: none;
    outline: 0 !important;
}
.formcontainer input:disabled {
	background:#eee url('../../../Images/icons/lock.svg') 98% 50% no-repeat;
	background-size:20px;
}
.formcontainer .hint {
	width:215px;
	padding-top:5px;
	font-size:90%;
	color:#aaa !important;
	white-space:wrap;
}
.oneline, .onelinetop {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.onelinetop {
	align-items:flex-start;
}
.notice {
	margin-bottom:10px;
	padding:10px;
	background:#fffcc0;
}
.buttons {
	position:fixed;
	bottom:0;
	width:100%;
	padding:20px 10px;
	margin-left:-10px;
	border-top:1px solid #ccc;
	display:flex;
	flex-direction:row;
	align-items:center;
}
.buttons button {
	width:160px;
	height:40px;
	background:#333;
	color:#fff;
	font-size:16px;
	border:none;
	border-radius:3px;
	margin-left:5px;
	margin-right:20px;
}
.buttons button:hover {
	opacity:.8;
	cursor:pointer;
}
.buttons button[disabled] {
	background:#333 url(../../../Images/loading.svg) 50% 50% no-repeat;
}