.title {
	display:flex;
	flex-direction:row;
	align-items:flex-start;
	border-bottom:1px solid #ddd;
	margin-bottom:10px;
}
.titlenoborder {
	border-bottom:none;
	margin:0;
}
.title:last-child {
	border:none;
	margin-bottom:0;
}
.title .cell {
	margin:0 40px 10px 0;
}
.title .cell .phone {
	white-space:nowrap;
}
.title .cell label span {
	display:block;
	margin:0 0 2px 0;
	color:#777;
	white-space:nowrap;
}
.productslist {
	width:100%;
}
.productslist .item {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:flex-start;
	padding:5px;
	border-bottom:1px dashed #ccc;
}
.productslist .item:last-child {
	border:none;
}
.productslist .item .name {
	width:100%;
}
.productslist .item .measure {
	color:#999;
	font-size:90%;
}
.productslist .item .count {
	color:#999;
	width:100px;
	text-align:right;
}
.productslist .item .price {
	width:160px;
	text-align:right;
}
.summaryview {
	width:100%;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	padding:20px 10px 10px;
	margin:0 0 10px 0;
	background-color:#f2f2f2;
	background-image:linear-gradient(135deg, #fff 5px, transparent 5px), linear-gradient(225deg, #fff 5px, transparent 5px);
	background-size:10px 10px;
	background-repeat:repeat-x;
}
.summaryview .summaryblock {
	width:30%;
}