.success, .error {
	padding:4px 8px;
	font-size:90%;
	text-decoration:blink;
	animation-name:blinker;
	animation-duration:.5s;
	animation-iteration-count:infinite;
	animation-timing-function:ease-in-out;
	animation-direction:alternate;
}
.success {
	color:#498200;
	background:#fffcc0;
}
.error {
	background:#ffccc0;
	color:#c00;
}
@keyframes blinker {
	from {opacity:1;}
	to {opacity:0;}
}