.container h4 {
	margin:0 0 15px 10px;
}
.formcontainer {
	margin-top:20px;
	margin-bottom:100px;
	padding:20px;
	border-radius:4px;
	box-shadow:0 2px 2px rgba(204,197,185,.5);
	background:#fff;
	position:relative;
	z-index:1;
	zoom:1;
	overflow:hidden;
}
.container .controls {
	display:flex;
	justify-content:flex-start;
	align-items:center;
	margin:20px 0;
}
.container .controls select {
	margin-right:20px;
	padding:8px 10px;
	border:1px solid #ccc;
	border-radius:5px;
}
.container .empty {
	margin-bottom:30px;
}
.container .loading {
	margin-bottom:30px;
}
.container .formcontainer .list {
	padding:6px 12px;
	margin-bottom:30px;
	border-radius:5px;
}
.container .formcontainer .list ul {
	columns:3;
}
.container .formcontainer .list ul li {
	display:flex;
	flex-direction:row;
	justify-content:flex-start;
	align-items:flex-start;
}
.container .formcontainer .list ul li div {
	margin-right:5px;
}
.container .formcontainer .list ul li div.num {
	color:#aaa;
}
.container .formcontainer .list ul li div.count {
	margin-right:0;
	color:#333;
	font-weight:bold;
	white-space:nowrap;
}