.container h4 {
	display:flex;
	align-items:center;
	justify-content:space-between;
}
.container h4 span a {
	margin-right:10px;
}
.formcontainer {
	margin-top:20px;
	margin-bottom:100px;
    padding:20px;
    border-radius:4px;
    box-shadow:0 2px 2px rgba(204,197,185,.5);
    background:#fff;
    position:relative;
    z-index:1;
    zoom:1;
    overflow:hidden;
}
.formcontainer form {
	max-width:900px;
}
.formcontainer .cell {
	margin:0 20px 20px 0;
}
.formcontainer .cell label span {
	display:block;
	margin:0 0 5px 0;
	color:#777;
}
.formcontainer input, .formcontainer select {
	display:block;
	width:200px;
	padding:10px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.formcontainer select {
	width:auto;
}
.formcontainer input:focus, .formcontainer select:focus {
    background-color: #fff;
    box-shadow: none;
    outline: 0 !important;
}
.oneline, .onelinefull {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.onelinefull .cell {
	width:33%;
}
.buttons {
	position: fixed;
	bottom:0;
	width:100%;
	padding:20px 10px 30px;
	margin-left:-30px;
	border-top:1px solid #ccc;
	display:flex;
	flex-direction:row;
	align-items:center;
	background:#f4f3ef;
}
.buttons button, .income {
	width:160px;
	height:40px;
	background:#333;
	color:#fff;
	font-size:16px;
	border:none;
	border-radius:3px;
	margin-left:5px;
	margin-right:20px;
}
.income {
	width:auto;
	padding:0 20px;
	margin:0 0 10px 0;
}
.buttons button:hover, .income:hover {
	opacity:.8;
	cursor:pointer;
}
.buttons button[disabled] {
	background:#333 url(../../../Images/loading.svg) 50% 50% no-repeat;
}
.container .linepositions {
	margin:10px 0 20px;
	padding-top:20px;
	border-top:1px solid #ccc;
}
.container h4 a.plus {
	display:block;
	width:26px;
	height:26px;
	background:url('../../../Images/icons/add.svg') 50% 50% no-repeat;
	cursor:pointer;
}
.container h4 a.plus:hover {
	opacity:.5;
}
.modalcontainer {
	height:100%;
	padding:20px;
	position:relative;
}
.modalcontainer h4.title {
	margin:10px 0 20px;
}
.modalcontainer .products {
	position:relative;
	height:calc(100% - 125px);
	overflow:auto;
	padding:0 0 5px 0;
}
.modalcontainer .products ul {
	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-wrap:wrap;
}
.modalcontainer .products ul li {
	width:33%;
	padding:5px 2px;
}
.modalcontainer .products ul li label input[type=radio] {
	width:auto;
	display:inline-block;
}
.modalcontainer .products ul li:hover {
	background:#eee;
}
.modalcontainer .search {
	margin:10px 0;
}
.modalcontainer .search input {
	width:100%;
	padding:6px;
}
.container .linepositions table {
	width:100%;
	margin:10px 0;
}
.container .linepositions table thead tr {
	background:#07385a;
	color:#fff;
	text-align:left;
}
.container .linepositions table th, .container .linepositions table td {
	padding:4px 8px;
}
.container .linepositions table tbody tr:nth-child(even) {
	background:#f4f3ef;
}
.container .linepositions table td a {
	color:#000;
	cursor:pointer;
}
.container .productslist {
	max-width:900px;
	margin:20px 0;
}
.container .productslist li {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:flex-start;
	padding:5px;
	border-bottom:1px dashed #ccc;
}
.container .productslist li:last-child {
	border:none;
}
.container .productslist li .name {
	width:100%;
}
.container .productslist li .measure {
	color:#999;
	width:80px;
}
.container .productslist li .price {
	width:160px;
	text-align:right;
}
.container .productslist li .total {
	width:160px;
	text-align:right;
}
.container .summary {
	padding-top:15px;
	border-top:1px solid #eee;
	text-align:right;
}
.container .summary b {
	display:block;
}
.modalcontainer .tabs {
	display:flex;
	flex-direction:row;
}
.modalcontainer .tabs .tab {
	display:inline-block;
	border:1px solid #ccc;
	padding:4px 10px;
	margin-right:20px;
	cursor:pointer;
}
.modalcontainer .tabs .tab:last-child {
	margin-right:0;
}
.modalcontainer .tabs .tabactive {
	background:#000;
	border-color:#000;
	color:#fff;
}