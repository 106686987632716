.container h4 {
	display:flex;
	align-items:center;
	justify-content:space-between;
}
.container h4 span a {
	margin-right:10px;
}
.container h4 a.add {
	display:flex;
	align-items:center;
	border:2px solid #000;
	font-size:15px;
	font-weight:bold;
	color:#000;
	text-decoration:none;
	padding:2px 12px 2px 6px;
	border-radius:4px;
}
.container h4 a.add:hover {
	border-color:#999;
	background:rgba(255,255,255,.8);
	color:#00000080;
}
.container h4 a.add:before {
	content:'';
	display:block;
	width:24px;
	height:24px;
	margin-right:4px;
	background:url('../../Images/icons/add.svg') 0 0 no-repeat;
}
.container h4 a.add:hover:before {
	opacity:.5;
}
.formcontainerputer {
	margin-bottom:100px;
}
.formcontainer {
	margin-top:20px;
	margin-bottom:10px;
	padding:20px;
	border-radius:4px;
	box-shadow:0 2px 2px rgba(204,197,185,.5);
	background:#fff;
	position:relative;
	z-index:1;
	zoom:1;
	overflow:hidden;
}
.formcontainer form {
	max-width:900px;
}
.formcontainer .cell {
	margin:0 20px 20px 0;
}
.formcontainer .cell label span {
	display:block;
	margin:0 0 5px 0;
	color:#777;
}
.formcontainer input, .formcontainer select, .formcontainer textarea {
	display:block;
	width:215px;
	padding:10px;
	border:1px solid #ccc5b9;
	background:#fffcf5;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
}
.formcontainer input:focus, .formcontainer select:focus, .formcontainer textarea:focus {
    background-color: #fff;
    box-shadow: none;
    outline: 0 !important;
}
.formcontainer textarea {
	width:685px;
}
.formcontainer textarea.desc {
	height:100px;
	resize:none;
}
.formcontainer h4.title {
	margin-bottom:20px;
}
.oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.notice {
	margin-bottom:10px;
	padding:10px;
	background:#fffcc0;
}
.buttons {
	position:fixed;
	bottom:0;
	width:100%;
	padding:20px 10px 30px;
	margin-left:-30px;
	border-top:1px solid #ccc;
	display:flex;
	flex-direction:row;
	align-items:center;
	background:#f4f3ef;
	z-index:100;
}
.buttons button {
	width:160px;
	height:40px;
	background:#333;
	color:#fff;
	font-size:16px;
	border:none;
	border-radius:3px;
	margin-left:5px;
	margin-right:20px;
}
.buttons button:hover {
	opacity:.8;
	cursor:pointer;
}
.buttons button[disabled] {
	background:#333 url(../../Images/loading.svg) 50% 50% no-repeat;
}
.moneyreportcontainer {margin-bottom:100px;}
.moneyreportcontainer .moneyreport {
	margin-bottom:20px;
}
.moneyreportcontainer .moneyreport b {
	display:block;
	margin-bottom:10px;
}
.moneyreportcontainer .moneyreport .income {
	display:flex;
	width:220px;
	flex-direction:row;
	align-items:center;
	border-bottom:1px solid #ccc;
}
.moneyreportcontainer .moneyreport .income:last-child {
	border-bottom:none;
}
.moneyreportcontainer .moneyreport .income span:first-child {
	width:120px;
}
.moneyreportcontainer .moneyreport .income span:last-child {
	width:100px;
	text-align:right;
}