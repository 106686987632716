.domruCard {
    position: absolute;
    bottom: 60px;
    left: 10px;
    background-color: rgba(232, 232, 232, .8);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    width: 260px;
    font-size: 14px;
    padding: 10px;
    border-radius: 5px;
    z-index: 200;
}

.domruCard ~ button {
    position: absolute;
    border: none;
    width: auto;
    text-align: center;
    padding: 10px;
    font-weight: bold;
    z-index: 200;
    background: rgb(255, 90, 87);
    color: white;
    border-radius: 25px;
    left: 10px;
    bottom: 10px;
}


.domruCard ~ button:active {
    opacity: .8;
}

.domruCard ~ button.expandButton:has(p) {
    width: 55px;
}


.domruCard ~ button:hover.expandButton:has(p) {
    width: 200px;
}

.domruCard ~ button:hover {
    background: rgb(248, 75, 72);
}

.domruCard ~ button:hover .arrowIcon {
    margin-top: 0px;
    position: absolute;
    transform: rotate(180deg);
    display: block;
    right: 5px;
    top: 25%;
    filter: invert(100%) sepia(3%) saturate(3005%) hue-rotate(326deg) brightness(115%) contrast(100%);

}
.domruCard ~ button .arrowIcon {
    display: none;
}

.domruCard ~ button span {
    display: none;
}

.domruCard ~ button .callIcon {
    filter: invert(100%) sepia(3%) saturate(3005%) hue-rotate(326deg) brightness(115%) contrast(100%);
    display: block;
}

.domruCard ~ button:hover .callIcon {
    display: none;
}

.domruCard ~ button:hover {
    width: 200px;
}

.domruCard ~ button:hover span {
    display: block;
}

.domruCard ~ button:hover p {
    display: none;
}

.domruCard.active {
    left: -300px;
}

.domruCard.active ~ button:hover .arrowIcon {
    transform: rotate(0deg);
    display: inline-block;
    position: absolute;
}

.domruCard.active ~ button .arrowIcon {
    display: none;
    position: absolute;
    transform: rotate(0deg);
}

.domruCard__header {
}

.domruCard__footer {

}

.card {
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 10px;
}

.card:first-child {
    margin-top: 10px;
}
  
  .userInfo {
    margin-bottom: 10px;
  }
  
  .name {
    font-size: 18px;
    font-weight: bold;
  }
  
  .number {
    font-size: 14px;
  }
  
  .status {
    font-size: 14px;
    color: gray;
  }

.expandButton {
    cursor: pointer;
    height: 45px;
}

.expandButton p {
    right: 8px;
    top: 50%;
    transform: translate(0, -50%);
    position: absolute;
}

.card button {
	padding: 5px 10px;
	color: #fff;
	border: none;
	border-radius: 3px;
	cursor: pointer;
	margin-right: 5px;
}

.offButton {
    background-color: rgb(150, 35, 35);
}

.newClient {
	background-color: #2980b9;
}

.newOrder {
    background-color: #29b94d;
}