.keyboardcontainer {
	position:absolute;
	background:#ccc;
	margin-top:10px;
	z-index:9999;
}
.keyboardcontainer .row {
	display:flex;
	flex-direction:row;
	flex-wrap:nowrap;
	justify-content:center;
	align-items:center;
}
.keyboardcontainer .cell {
	color:#000;
	height:50px;
	padding:0 20px;
	font-size:150%;
	margin:5px;
	box-shadow:0 0 3px -1px rgb(0 0 0 / 30%);
    border-radius:5px;
    box-sizing:border-box;
    cursor:pointer;
    display:flex;
    align-items:center;
    justify-content:center;
	background:#fff;
}
.keyboardcontainer .cell.space {
	width:80%;
}