.container {
	position:relative;
	width:800px;
	margin:80px auto 0;
	padding:30px;
}
.container .logo {
	display:flex;
	align-items:center;
	margin:0 0 30px 0;
}
.container .logo img {
	height:80px;
	display:block;
}
.container .formcontainer {
	display:flex;
	justify-content:space-between;
	align-items:flex-end;
	margin-top:80px;
}
.container .formcontainer .form {
	width:460px;
}
.container .formcontainer .form p {
	margin-bottom:40px;
	font-size:140%;
	line-height:1.2;
}
.container .formcontainer .form p span {
	display:block;
	margin-top:10px;
	font-size:80%;
	color:#555;
}
.container .formcontainer .form .column {
	position:relative;
}
.container .formcontainer .form .column .placeholder {
	position:absolute;
	left:4px;
	top:4px;
	width:150px;
	height:52px;
	padding:10px;
	display:flex;
	align-items:center;
	line-height:1.2;
	background:#999;
	color:#fff;
}
.container .formcontainer .form .column .input {
	padding:10px;
	width:440px;
	height:60px;
	border:none;
	font-size:28px;
	margin-bottom:10px;
	padding-left:175px;
	background:#fff;
	border:2px solid #aaa;
}
.container .formcontainer .form .column .password {
	letter-spacing:10px;
	margin:0;
}
.container .formcontainer .form .column .focus {
	border-color:rgba(0,0,255);
	box-shadow:0 1px 1px rgba(0,0,0,.075) inset, 0 0 8px rgba(0,0,255,.6);
	outline:0 none;
}
.container .formcontainer .form .column .input[iserror=true] {
	border-color:#ff402b;
	background-color:#fff9f8;
}
.container .formcontainer .form .column .input[issuccess=true] {
	border-color:#38bd4c;
}
.container .formcontainer .keypad {
	width:280px;
	display:flex;
	justify-content:space-between;
	align-items:center;
	flex-wrap:wrap;
}
.container .formcontainer .keypad .num {
	width:90px;
	height:60px;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-bottom:5px;
	font-size:32px;
	font-weight:700;
	color:#fff;
	background:#555;
	cursor:pointer;
}
.container .formcontainer .keypad .delete {
	font-size:24px;
}
.container .formcontainer .keypad .numlast {
	margin:0;
}
.container .controls {
	display:flex;
	align-items:center;
	justify-content:space-between;
	margin-top:20px;
}
.container .controls button {
	width:210px;
	border:none;
	font-size:20px;
	font-weight:bold;
	padding:15px;
	color:#fff;
	cursor:pointer;
}
.container .controls button.open {
	margin-left:auto;
	margin-right:20px;
	background:#328f51;
}
.container .controls button.enter {
	width:280px;
	background:#b52b2b;
}
.container .areacontainer {
	margin-left:auto;
	align-self:flex-start;
}
.container .areacontainer h4 {
	margin:3px 0 6px;
}
.container .areacontainer select {
	display:block;
	width:260px;
	padding:8px 14px;
	font-size:110%;
	border:1px solid #ccc5b9;
	border-radius:4px;
	transition:background-color 0.3s ease 0s;
	box-shadow:none;
	background:#fffcf5 url(../../Images/icons/arrow_down.svg) no-repeat 99% 50%;
    appearance:none;
}
.container .areacontainer select option {
	font-size:110%;
}
.container .areacontainer select:focus {
    background-color:#fff;
    box-shadow:none;
    outline:0 !important;
}