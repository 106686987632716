.container {
	display:flex;
	flex:1;
	flex-direction:row;
	width:100%;
	background:#f4f3ef;
}
.container .main {
	flex:1;
	margin:13px 15px;
}
.container .side {
	background:#fff;
	box-shadow:0 2px 2px rgba(204,197,185,.5);
}
.container .main h1 {font-size:28px;}