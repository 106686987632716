.container h4 {
	margin:0 0 15px 10px;
}
.formcontainer {
	margin-top:20px;
	margin-bottom:30px;
	padding:20px;
	border-radius:4px;
	box-shadow:0 2px 2px rgba(204,197,185,.5);
	background:#fff;
	position:relative;
	z-index:1;
	zoom:1;
	overflow:hidden;
}
.oneline {
	display:flex;
	flex-direction:row;
	align-items:center;
}
.statcontainerlight {
	padding:10px 10px 30px 10px;
	background:#f8f8f8;
}
.statcontainer {
	display:flex;
	flex-direction:row;
	justify-content:center;
	align-items:flex-start;
	margin-bottom:50px;
}
.statcontainer h4 {
	font-weight:normal;
	font-size:110%;
	margin:0 0 0 5px;
}
.statcontainer .chart {
	width:33%;
	height:220px;
	margin-bottom:20px;
}
.statcontainer h4 {
	margin-bottom:20px;
}
.container .list {
	background:#f8f8f8;
	padding:6px 12px;
	margin-bottom:30px;
	border-radius:5px;
}
.container .list ul {
	columns:3;
}
.container .list ul li {
	display:flex;
	flex-direction:row;
	justify-content:flex-start;
	align-items:flex-start;
}
.container .list ul li div {
	margin-right:5px;
}
.container .list ul li div.num {
	color:#aaa;
}
.container .list ul li div.count {
	margin-right:0;
	color:#333;
	font-weight:bold;
	white-space:nowrap;
}
.statcontainer .legend {
	margin:40px 0 0 20px;
}
.statcontainer .legend li {
	display:flex;
	justify-content:flex-start;
	align-items:center;
}
.statcontainer .legend li div {
	width:20px;
	height:10px;
	margin-right:5px;
}
.statlink {
	margin:0 0 15px 10px;
}
.statcontainer .notfound {
	margin-right:auto;
}