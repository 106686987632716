.courier_stats {
  width: 100%;
  background-color: #fff;
  border-radius: 14px;
  padding-top: 15px;
  margin: 0;
  top: 0;
  left: 0;
}

.tooltip {
  background-color: #EEE;
  position: absolute;
  transform: translateX(-45%);
  padding: 10px 15px;
  border-radius: 10px;
  box-shadow: 0 0 2px rgba(0,0,0,.3);
}

.courier_stats .header {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 5px;
}

.courier_stats .header .date {
  display: flex;
  justify-content: space-between;
}

.courier_stats .header .date div:last-child {
  margin-left: 10px;
}

.courier_stats .header .date input {
  margin-left: 10px;
  font-size: 16px;
  border: none;
  padding: 5px 10px;
  background-color: #eee;
  border-radius: 8px;
}

.courier_stats .header .title {
  font-weight: bold;
}

.courier_stats .container .header {
  padding: 10px 0px;
  font-size: 16px;
  width: 100%;
  text-align: left;
}

.courier_stats .container .header span i {
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: bold;
  font-style: normal;
}


.courier_stats .container .header span i.inwork {
  background-color: #1df;
}


.courier_stats .container .header span i.done {
  background-color: #1af;
}


.courier_stats .container .header span i.error {
  background-color: #f39c12;
}

.courier_stats .container .content .item {
  display: flex;
  align-items: center;
}

.courier_stats .container .content .item .courier {
  padding: 5px;
  width: 20%;
}

.courier_stats .container .content .item .courier span.offline, .courier_stats .container .content .item .courier span.online {
    font-size: 12px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    height: 6px;
    width: 6px;
}

.courier_stats .container .content .item .courier span.online {
  color: #FFF;
  background-color: #2ecc71;
}

.courier_stats .container .content .item .courier span.offline {
  color: #FFF;
  background-color: #e74c3c;
}

.courier_stats .container .content .item:nth-child(2) .courier {
  font-weight: bold;
}

.courier_stats .container .content .item .area {
  padding: 5px;
  width: 16%;
}

.courier_stats .container .content .item .position {
  padding: 5px;
  width: 16%;
}

.courier_stats .container .content .item:nth-child(2) .area {
  font-weight: bold;
}

.courier_stats .container .content .item .orders {
  padding: 5px;
  width: 60%;
  display: flex;
}
.courier_stats .container .content .item:nth-child(2) .orders {
  font-weight: bold;
}

.courier_stats .container .content .item .orders .order {
  width: 20px;
  border-radius: 4px;
  height: 20px;
  background-color: #1df;
  display: block;
  margin-right: 5px;
}

.courier_stats .container .content .item .orders .order:hover, .courier_stats .container .content .item .orders .success:hover {
  cursor: pointer;
  opacity: 0.8;
}

.courier_stats .container .content .item .orders .success {
  width: 20px;
  border-radius: 4px;
  height: 20px;
  background-color: #1af;
  display: block;
  margin-right: 5px;
}