.sidemenuside {
	width:240px;
	height:100%;
	background:#fff;
}
.sidemenuside .menucontainer {
	position:fixed;
	width:240px;
	height:100%;
	padding:10px 20px 0;
	overflow:auto;
}
.sidemenuside ul {
	margin:5px 0 20px;
}
.sidemenuside ul li {
	padding:1px 0;
}
.sidemenuside ul li a {
	display:block;
	text-decoration:none;
	color:#555;
}
.sidemenuside ul li:hover a {
	color:#000;
	background:#eee;
}
.sidemenuside ul a img {
	width:22px;
	position:relative;
	top:6px;
}
.sidemenuside ul a:hover {
	background:transparent;
}
.sidemenuside .logoff {
	margin:20px 0;
}
.sidemenuside .logoff a {
	font-size:100%;
	color:#777;
	text-decoration:none;
}