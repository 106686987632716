.container p {
	margin:20px 0;
	font-size:16px;
	text-align:center;
}
.container p a {
	color:#2475e6;
	text-decoration:none;
	padding-bottom:3px;
	border-bottom:1px solid rgba(36,117,230,.4);
}
.container h1 {
	text-align:center;
	margin-bottom:50px;
}
.container h1 span  {
	font-size:55%;
	font-weight:normal;
	display:block;
	color:#555;
}
.container .logo {
	height:90px;
	margin:80px auto 80px;
	display:block;
}
.container button.btn {
	display:block;
	width:300px;
	padding:15px 0;
	margin:40px auto 30px;
	border-radius:6px;
	border:none;
    box-shadow:0 2px 1px 0 rgba(255,229,59,.36);
    background:#226df6;
	font-size:18px;
	color:#fff;
	transition:.3s;
	display:flex;
	align-items:center;
	justify-content:center;
}
.container button.btn:hover {
	cursor:pointer;
	opacity:.8;
}