.container {
	display:flex;
	flex-direction:column;
	width:100%;
	height:100vh;
	background:#efefef;
}
.header {
	width:100%;
	background:#fff;
	box-shadow:0 2px 10px rgba(37,48,62,.2);
}
.header .inner {
	display:flex;
	width:1024px;
	margin:0 auto;
	padding:15px 20px;
	align-items:center;
}
.header .menuicon {
	width:24px;
	margin-right:20px;
	cursor:pointer;
	margin-top:-3px;
}
.header h1 {
	font-size:170%;
	line-height:1.1;
}
.header .innerinner {
	display:flex;
	flex-direction:row;
	margin-left:auto;
}
.header .innerinner .ds {
	margin-right:40px;
	padding:0 15px;
	text-align:center;
	font-size:120%;
	font-weight:bold;
	background:#f2f2f2;
	border-radius:3px;
}
.header .innerinner .ds span {
	display:block;
	font-size:60%;
	font-weight:normal;
}
.header .innerinner .clock {
	text-align:right;
	font-size:120%;
	font-weight:bold;
}
.header .innerinner .clock span {
	display:block;
	font-size:60%;
	font-weight:normal;
}